import React from "react";
import styles from "./PromptInput.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faSpinner } from "@fortawesome/free-solid-svg-icons";

interface PromptInputProps {
  handleSendMessage: (input: string) => void;
  placeholder?: string;
  className?: string;
}
export default function PromptInput({
  handleSendMessage,
  placeholder,
  className,
}: PromptInputProps): JSX.Element {
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const MAX_INPUT_HEIGHT = 150;

  const [isInputDisabled, setIsInputDisabled] = React.useState(true);
  const [isSendingMessage, setIsSendingMessage] = React.useState(false);

  return (
    <div className={`${className ?? ""} ${styles.messageBox}`}>
      <textarea
        name="message"
        ref={inputRef}
        rows={1}
        className={styles.messageInput}
        placeholder={placeholder ?? "Type your message here..."}
        onKeyDown={(e) => {
          if (
            e.key === "Enter" &&
            !e.shiftKey &&
            inputRef?.current?.value &&
            inputRef.current.value.length > 0
          ) {
            setIsSendingMessage(true);
            e.preventDefault();
            void handleSendMessage(inputRef.current.value);
            inputRef.current.value = "";
            setIsSendingMessage(false);
          }
        }}
        onChange={(_) => {
          if (inputRef.current) {
            inputRef.current.style.height = "auto"; // Reset height to auto to shrink if necessary
            if (inputRef.current.scrollHeight > MAX_INPUT_HEIGHT) {
              inputRef.current.style.height = `${MAX_INPUT_HEIGHT}px`; // Set to maxHeight if scrollHeight exceeds it
            } else {
              inputRef.current.style.height = `${inputRef.current.scrollHeight.toString()}px`; // Set height to scrollHeight
            }
          }

          if (inputRef.current?.value && inputRef.current.value.length > 0) {
            setIsInputDisabled(false);
          } else {
            setIsInputDisabled(true);
          }
        }}
      />
      <button
        className={styles.sendButton}
        disabled={isInputDisabled}
        onClick={() => {
          if (
            !isInputDisabled &&
            inputRef?.current?.value &&
            inputRef.current.value.length > 0
          ) {
            setIsSendingMessage(true);
            void handleSendMessage(inputRef.current.value);
            inputRef.current.value = "";
            setIsSendingMessage(false);
          }
        }}
      >
        <FontAwesomeIcon
          icon={isSendingMessage ? faSpinner : faPaperPlane}
          className={`${isSendingMessage ? "fa-spin" : styles.sendIcon}`}
        />
      </button>
    </div>
  );
}

import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Action } from "..";
import { useTerminalSession } from "../../../../common_components/ChamIDE/contexts/TerminalSessionContext/TerminalSessionContext";
import { useDirectoryTree } from "../../../../common_components/ChamIDE/contexts/DirectoryContext/DirectoryContext";
import { getSessionDirectory } from "../../../../common_components/ChamIDE/components/utils/getSessionDirectory";

export interface DoneAction {
  id: string;
  actionType: "DONE";
}

export enum DoneState {
  IGNORED = "IGNORED",
  DIRECTORY_RELOADED = "DIRECTORY_RELOADED",
  DONE = "DONE",
}

export const useDoneActionHandler = (
  action: Action,
): {
  doneState: DoneState;
  activeAction: DoneAction | undefined;
} => {
  const [doneState, setDoneState] = useState<DoneState>(DoneState.IGNORED);
  const { terminalSessionId } = useTerminalSession();
  const { directoryTree, setDirectoryTree } = useDirectoryTree();

  const reloadDirectory = useCallback(async () => {
    if (terminalSessionId && setDirectoryTree) {
      const newDirectoryTree = await getSessionDirectory(terminalSessionId);
      setDirectoryTree(newDirectoryTree);
    }
  }, [terminalSessionId, setDirectoryTree]);

  const activeAction = useMemo<DoneAction | undefined>(() => {
    if (action.actionType === "DONE") {
      return action;
    }
  }, [action]);

  useEffect(() => {
    if (activeAction) {
      reloadDirectory()
        .then(() => {
          setDoneState(DoneState.DIRECTORY_RELOADED);
        })
        .catch((err) => {
          console.error("Could not reload directory due to", err);
          setDoneState(DoneState.DONE);
        });
    } else {
      setDoneState(DoneState.IGNORED);
    }
  }, [activeAction]);

  useLayoutEffect(() => {
    setDoneState(DoneState.DONE);
  }, [directoryTree, doneState]);

  return { doneState, activeAction };
};

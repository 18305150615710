import React from "react";
import Logo from "../Logo/logo";
import styles from "./MainHeaderComponent.module.css";
import { Link } from "react-router-dom";
import { branding } from "../../../config/branding";
import DecentralHubLogo from "../../../assets/decentralhub-logo.png";
interface MainHeaderComponentProps {
  headerElements: React.ReactNode;
}

export default function MainHeaderComponent({
  headerElements,
}: MainHeaderComponentProps): JSX.Element {
  return (
    <div className={styles.container}>
      <Link to="/">
        {branding.name === "DecentralHub" ? (
          <img src={DecentralHubLogo} style={{ width: "10rem" }} />
        ) : (
          <Logo className={styles.logo} />
        )}
      </Link>
      <div className={styles.signupContainer}>{headerElements}</div>
    </div>
  );
}

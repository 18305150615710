import React, { useEffect, useMemo } from "react";

import styles from "./Sidebar.module.css";
import { WorkspaceSideBar } from "../../ChamIDE/components/DirectoryTree/WorkspaceSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faMessage,
  faNewspaper,
} from "@fortawesome/free-regular-svg-icons";
import { Tooltip } from "@mui/material";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { ClarificationsView } from "../../../Lesson/components/Clarifications";
import { SidebarViewState, ViewState } from "../types";
import { faCode, faGlobe, faTerminal } from "@fortawesome/free-solid-svg-icons";
import { useViewContext } from "../../ChamIDE/contexts/ViewContext/ViewContext";
import {
  DeviceType,
  useResponsiveness,
} from "../../../../hooks/useResponsiveness";

interface SideBarProps {
  setError: (error: string) => void;
  width: number;
  setActualWidth?: (width: number) => void;
  actualWidth?: number;
}

export const SideBar = ({
  setError,
  width,
  setActualWidth,
  actualWidth,
}: SideBarProps): JSX.Element => {
  const {
    currentView,
    setCurrentView,
    currentSidebarView,
    setCurrentSidebarView,
  } = useViewContext();
  const { deviceType, windowWidth } = useResponsiveness();

  const isSidebarOpen = useMemo(
    () => currentSidebarView !== SidebarViewState.None,
    [currentSidebarView]
  );

  useEffect(() => {
    let newActualWidth =
      deviceType === DeviceType.Desktop ? width : windowWidth;
    newActualWidth = isSidebarOpen ? newActualWidth : 53;
    setActualWidth?.(newActualWidth);
  }, [isSidebarOpen, setActualWidth, width, windowWidth]);

  const tabs = React.useMemo(
    () => [
      {
        icon: faNewspaper,
        onClick: () => {
          setCurrentView(ViewState.Lesson);
          setCurrentSidebarView(SidebarViewState.None);
        },
        active: currentView === ViewState.Lesson,
        tooltip: "Lesson",
      },
      {
        icon: faFolder,
        onClick: () => {
          if (deviceType === DeviceType.Mobile) {
            setCurrentView(ViewState.None);
          }
          setCurrentSidebarView(
            currentSidebarView === SidebarViewState.File &&
              deviceType === DeviceType.Desktop
              ? SidebarViewState.None
              : SidebarViewState.File
          );
        },
        active: currentSidebarView === SidebarViewState.File,
        tooltip: "Files",
      },
      {
        icon: faCode,
        onClick: () => {
          setCurrentView(ViewState.IDE);
          setCurrentSidebarView(SidebarViewState.None);
        },
        active: currentView === ViewState.IDE,
        tooltip: "IDE",
        mobileOnly: true,
      },
      {
        icon: faTerminal,
        onClick: () => {
          setCurrentView(ViewState.Terminal);
          setCurrentSidebarView(SidebarViewState.None);
        },
        active: currentView === ViewState.Terminal,
        tooltip: "Terminal",
        mobileOnly: true,
      },
      {
        icon: faGlobe,
        onClick: () => {
          setCurrentView(ViewState.Browser);
          setCurrentSidebarView(SidebarViewState.None);
        },
        active: currentView === ViewState.Browser,
        tooltip: "Browser",
        mobileOnly: true,
      },
      {
        icon: faMessage,
        onClick: () => {
          if (deviceType === DeviceType.Mobile) {
            setCurrentView(ViewState.None);
          }
          setCurrentSidebarView(
            currentSidebarView === SidebarViewState.Clarifications &&
              deviceType === DeviceType.Desktop
              ? SidebarViewState.None
              : SidebarViewState.Clarifications
          );
        },
        active: currentSidebarView === SidebarViewState.Clarifications,
        tooltip: "Clarifications",
      },
      {
        icon: faGithub,
        onClick: () => {
          setCurrentView(ViewState.Github);
          setCurrentSidebarView(SidebarViewState.None);
        },
        active: currentView === ViewState.Github,
        tooltip: "Github",
      },
    ],
    [
      currentView,
      setCurrentView,
      currentSidebarView,
      setCurrentSidebarView,
      deviceType,
    ]
  );

  return (
    <div
      className={styles.sideBarContainer}
      style={{
        width: actualWidth,
      }}
    >
      <div className={styles.sideTab}>
        {tabs.map((tab, index) => (
          <Tooltip
            hidden={tab.mobileOnly && deviceType === DeviceType.Desktop}
            key={index}
            title={tab.tooltip}
          >
            <div
              className={tab.active ? styles.activeTab : styles.tab}
              onClick={tab.onClick}
            >
              <FontAwesomeIcon icon={tab.icon} />
            </div>
          </Tooltip>
        ))}
      </div>
      {isSidebarOpen && (
        <div className={styles.sideBar}>
          {currentSidebarView === SidebarViewState.File && (
            <WorkspaceSideBar setError={setError} />
          )}
          {currentSidebarView === SidebarViewState.Clarifications && (
            <ClarificationsView />
          )}
        </div>
      )}
    </div>
  );
};

export enum ViewState {
  Lesson = "lesson",
  IDE = "ide",
  Terminal = "terminal",
  ClarificationChat = "clarification-chat",
  Github = "github",
  Browser = "browser",
  None = "none",
}

export enum SidebarViewState {
  File = "file",
  Clarifications = "clarifications",
  None = "none",
}

import { useState, useMemo, useEffect, useLayoutEffect } from "react";
import { Action } from "..";
import { useTerminalSession } from "../../../../common_components/ChamIDE/contexts/TerminalSessionContext/TerminalSessionContext";
import { useLessonId } from "../../../../common_components/LessonContext/LessonContext";

export interface ExecAction {
  id: string;
  actionType: "EXEC";
  command: string;
}

export enum ExecState {
  IGNORED = "IGNORED",
  REQUESTED = "REQUESTED",
  SHOW_TERMINAL_TRIGGERED = "SHOW_TERMINAL_TRIGGERED",
  AGENT_TERMINAL_REQUESTED = "AGENT_TERMINAL_REQUESTED",
  AGENT_TERMINAL_ACTIVE = "AGENT_TERMINAL_ACTIVE",
  CLEANUP_REQUESTED = "CLEANUP_REQUESTED",
  READY_FOR_COMMAND = "READY_FOR_COMMAND",
  COMMAND_ISSUED = "COMMAND_ISSUED",
  DONE = "DONE",
}

enum CleanupState {
  IGNORED = "IGNORED",
  REQUESTED = "REQUESTED",
  COMPLETE = "COMPLETE",
}

export const useExecActionHandler = (
  action: Action,
  requestId: string,
): {
  activeAction: ExecAction | undefined;
  execState: ExecState;
} => {
  const [execState, setExecState] = useState<ExecState>(ExecState.IGNORED);
  const [cleanupState, setCleanupState] = useState<CleanupState>(
    CleanupState.IGNORED,
  );
  const [, setCurrentRequestId] = useState<string>();
  const lessonId = useLessonId();
  const {
    setProgramToRun,
    terminalConnected,
    showTerminal,
    handleAddTerminal,
    terminalDisplayed,
    terminals,
    activeTerminal,
    setActiveTerminal,
    activeProcesses,
    programToRun,
  } = useTerminalSession();

  const activeAction = useMemo<ExecAction | undefined>(() => {
    if (action.actionType === "EXEC") {
      return action;
    } else {
      setExecState(ExecState.IGNORED);
    }
  }, [action]);

  useEffect(() => {
    if (activeAction && requestId) {
      setCurrentRequestId((oldValue) => {
        if (oldValue === requestId) {
          setCleanupState(CleanupState.IGNORED);
          setExecState(ExecState.REQUESTED);
          return oldValue;
        } else {
          // For new requests, we want to cleanup first
          setCleanupState(CleanupState.REQUESTED);
          setExecState(ExecState.REQUESTED);
          return requestId;
        }
      });
    }
  }, [activeAction, requestId]);

  useEffect(() => {
    if (activeAction && execState === ExecState.REQUESTED) {
      setExecState(ExecState.SHOW_TERMINAL_TRIGGERED);
      showTerminal();
    }
  }, [activeAction, execState]);

  // We want this to be triggered only after we're sure terminal has been displayed
  useLayoutEffect(() => {
    if (
      activeAction &&
      terminalDisplayed &&
      execState === ExecState.SHOW_TERMINAL_TRIGGERED &&
      terminalConnected
    ) {
      const agentTerminalList = terminals.filter(
        (terminal) => terminal.name === "agent",
      );
      if (agentTerminalList.length === 0) {
        setExecState(ExecState.AGENT_TERMINAL_REQUESTED);
        void handleAddTerminal(lessonId, "agent");
      } else if (activeTerminal?.name !== "agent") {
        setExecState(ExecState.AGENT_TERMINAL_REQUESTED);
        const agentTerminal = agentTerminalList[0];
        setActiveTerminal(agentTerminal);
      } else {
        setExecState(ExecState.AGENT_TERMINAL_ACTIVE);
      }
    }
  }, [terminalDisplayed, execState, terminalConnected]);

  useEffect(() => {
    if (
      activeAction &&
      (execState === ExecState.AGENT_TERMINAL_REQUESTED ||
        execState === ExecState.AGENT_TERMINAL_ACTIVE) &&
      activeTerminal?.name === "agent" &&
      activeProcesses.has(activeTerminal.id)
    ) {
      if (cleanupState === CleanupState.REQUESTED) {
        // Send Ctrl + C
        setProgramToRun("\u0003" + "\n");
        setExecState(ExecState.CLEANUP_REQUESTED);
      } else {
        setExecState(ExecState.READY_FOR_COMMAND);
      }
    }
  }, [activeTerminal, execState, cleanupState, activeProcesses]);

  // After the command is complete, program to run is cleared, so we know we're ready to move to READY_FOR_COMMAND
  useEffect(() => {
    if (
      activeAction &&
      cleanupState === CleanupState.REQUESTED &&
      execState === ExecState.CLEANUP_REQUESTED &&
      programToRun === undefined
    ) {
      setCleanupState(CleanupState.COMPLETE);
      setExecState(ExecState.READY_FOR_COMMAND);
    }
  }, [programToRun, execState, cleanupState, activeAction]);

  useEffect(() => {
    if (
      activeAction &&
      (cleanupState === CleanupState.IGNORED ||
        cleanupState === CleanupState.COMPLETE) &&
      execState === ExecState.READY_FOR_COMMAND
    ) {
      if (activeAction.command) {
        setProgramToRun(activeAction.command + "\n");
        setExecState(ExecState.COMMAND_ISSUED);
      } else {
        setExecState(ExecState.DONE);
      }
    }
  }, [execState, cleanupState, activeAction]);

  useEffect(() => {
    if (
      activeAction &&
      execState === ExecState.COMMAND_ISSUED &&
      // After the command is complete, program to run is cleared
      programToRun === undefined
    ) {
      setExecState(ExecState.DONE);
    }
  }, [programToRun, execState, activeAction]);

  return {
    activeAction,
    execState,
  };
};

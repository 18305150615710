import React, { useCallback, useEffect, useMemo, useState } from "react";
import StompJS from "stompjs";
import { services } from "../../../../config";
import { useActionInstructorSession } from "./hooks/useActionInstructorSession";
import styles from "./ActionAgent.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import {
  OpenAction,
  OpenState,
  useOpenActionHandler,
} from "./hooks/useOpenActionHandler";
import {
  WriteAction,
  WriteState,
  useWriteActionHandler,
} from "./hooks/useWriteActionHandler";
import {
  DoneAction,
  DoneState,
  useDoneActionHandler,
} from "./hooks/useDoneActionHandler";
import {
  ExecAction,
  ExecState,
  useExecActionHandler,
} from "./hooks/useExecActionHandler";

export type Action =
  | OpenAction
  | EmptyAction
  | ExecAction
  | WriteAction
  | DoneAction;

export interface EmptyAction {
  actionType: "EMPTY";
  id: "";
}
interface ActionAgentButtonProps {
  lessonId: string;
  pageId: string;
}

export const ActionAgentButton = ({
  lessonId,
  pageId,
}: ActionAgentButtonProps): JSX.Element => {
  const stompClient = useMemo<StompJS.Client | undefined>(() => {
    return StompJS.client(services.AI_SERVICE_WEBSOCKET_API);
  }, []);
  const [stompConnected, setStompConnected] = useState<boolean>(false);
  const [action, setAction] = useState<Action>({ actionType: "EMPTY", id: "" });
  const [hasRequestTriggered, setRequestTriggered] = useState<boolean>(false);
  const [requestId, setRequestId] = useState<string>(
    crypto.randomUUID().toString(),
  );

  const { openState, activeAction: activeOpenAction } =
    useOpenActionHandler(action);

  const { writeState, activeAction: activeWriteAction } =
    useWriteActionHandler(action);

  const { execState, activeAction: activeExecAction } = useExecActionHandler(
    action,
    requestId,
  );

  const { doneState, activeAction: activeDoneAction } =
    useDoneActionHandler(action);

  const sendAck = useCallback(
    (actionId: string) => {
      console.log("ACK SENT", actionId);
      stompClient?.send(
        "/action/ack",
        {},
        JSON.stringify({
          requestId,
          actionId,
        }),
      );
    },
    [stompClient, requestId],
  );

  useEffect(() => {
    if (stompClient && !stompConnected) {
      stompClient.connect(
        "",
        "",
        () => {
          setStompConnected?.(true);
          console.log("Connected");
        },
        () => {
          setStompConnected?.(false);
          console.log("Disconnected");
        },
      );
    } else if (stompConnected) {
      return;
    }
    if (stompClient) {
      stompClient.debug = () => null;
    }
  }, [stompClient, stompClient?.connected]);

  useEffect(() => {
    if (openState === OpenState.DONE && activeOpenAction?.id === action.id) {
      sendAck(activeOpenAction.id);
    }
  }, [openState, activeOpenAction, sendAck]);

  useEffect(() => {
    if (writeState === WriteState.DONE && activeWriteAction?.id === action.id) {
      sendAck(activeWriteAction.id);
    }
  }, [writeState, activeOpenAction, sendAck]);

  useEffect(() => {
    if (execState === ExecState.DONE && activeExecAction?.id === action.id) {
      sendAck(activeExecAction.id);
    }
  }, [execState, activeOpenAction, sendAck]);

  useEffect(() => {
    if (doneState === DoneState.DONE && activeDoneAction?.id === action.id) {
      setRequestId(crypto.randomUUID().toString());
      setRequestTriggered(false);
    }
  }, [doneState, activeDoneAction]);

  const { startSession } = useActionInstructorSession(
    stompClient,
    stompConnected,
    lessonId,
    pageId,
    setAction,
    requestId,
  );

  return (
    <button
      className={styles.container}
      disabled={hasRequestTriggered}
      onClick={() => {
        startSession();
        setRequestTriggered(true);
      }}
    >
      <FontAwesomeIcon icon={faRobot} /> Do it for me
    </button>
  );
};

import * as React from "react";
import DashboardComponent from "../../common_components/DashboardComponent";
import styles from "./Checkout.module.css";
import { Status } from "../../../__generated__/graphql";
import { useUserApiClient } from "../../../hooks/useUserApiClient";
import { Redirect } from "react-router-dom";
import { LoadingComponentFullScreen } from "../../common_components/LoadingComponent";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { keys } from "../../../config";

const stripePromise = loadStripe(
  // TODO: replace with cham
  // TODO: Conditional to check environment
  keys.STRIPE_KEY,
  {}
);

export const SubscriptionCheckoutPage = (): JSX.Element => {
  const { getUserSubscriptionStatus } = useUserApiClient();
  const [isUserSubscribed, setIsUserSubscribed] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { createCheckoutSession } = useUserApiClient();

  const fetchClientSecret = React.useCallback(async () => {
    return await createCheckoutSession().then(
      (session) => session.clientSecret
    );
  }, []);
  const options = { fetchClientSecret };

  React.useEffect(() => {
    const loadIsSubscribed = async (): Promise<void> => {
      setLoading(true);
      const { status } = await getUserSubscriptionStatus();
      setIsUserSubscribed(status === Status.Active);
      setLoading(false);
    };

    void loadIsSubscribed();
  }, []);

  return (
    <DashboardComponent>
      {loading ? (
        <LoadingComponentFullScreen />
      ) : isUserSubscribed ? (
        <Redirect to={"/menu"} />
      ) : (
        <div className={styles.container}>
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </div>
      )}
    </DashboardComponent>
  );
};

import React from "react";
import styles from "./ChamBrowserContainer.module.css";

import ErrorComponent from "../../../ErrorComponent";
import {
  faArrowUpRightFromSquare,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useExecutionService } from "../../contexts/ExecutionServiceContext/ExecutionServiceContext";
import { IDEButton } from "../ChamEditor/components/IDEButton/IDEButton";

interface IBrowserProps {
  toggleShowChamBrowser: () => void;
}

export const ChamBrowserContainer: React.FC<IBrowserProps> = ({
  toggleShowChamBrowser,
}) => {
  const IFRAME_ID = "browser-in-browser-mokmijnuhb";
  const URL_HEADING_ID = "browser-in-browser-zdrtxcgyvhu";
  const [portNumber, setPortNumber] = React.useState<string>();
  const [relativePath, setRelativePath] = React.useState<string>("");
  const { portUrl } = useExecutionService();
  const url =
    portNumber != null && portUrl != null
      ? `https://${portNumber}-${portUrl}/${relativePath}`
      : null;

  const handleSetPortNumber = React.useCallback(
    (number: string) => {
      setPortNumber(number);
    },
    [setPortNumber],
  );

  const handleSetRelativePath = React.useCallback(
    (path: string) => {
      setRelativePath(path);
    },
    [setRelativePath],
  );
  const [error, setError] = React.useState<string | undefined>();

  const iFrame = document.getElementById(IFRAME_ID) as HTMLIFrameElement;

  const reload = (): void => {
    if (url != null) {
      iFrame.src = url;
    }
  };

  const openInNewWindow = (): Window | null => {
    if (url != null) {
      return window.open(url);
    } else {
      return null;
    }
  };

  return (
    <>
      {error && (
        <div className={styles.errorComponentContainer}>
          <ErrorComponent
            title="Something went wrong"
            close={() => setError("")}
          >
            {error}
          </ErrorComponent>
        </div>
      )}
      <div className={styles.editorToolbar}>
        <h2>Browser</h2>
        <div className={styles.editorToolbarButtons}>
          <IDEButton
            className={styles.editorToobarIdeButton}
            toggleShowChamBrowser={toggleShowChamBrowser}
          />
        </div>
      </div>
      <div className={styles.browserContainer}>
        <div className={styles.header}>
          <button onClick={reload}>
            <FontAwesomeIcon className={styles.browserIcon} icon={faRefresh} />
          </button>
          <div className={styles.urlBar}>
            <span id={URL_HEADING_ID} className={styles.urlText}>
              localhost:
              <input
                className={styles.portInput}
                type="text"
                placeholder="####"
                onSubmit={(e) =>
                  handleSetPortNumber((e.target as HTMLInputElement).value)
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSetPortNumber((e.target as HTMLInputElement).value);
                  }
                }}
              />
              /
              <input
                className={styles.portInputLarge}
                type="text"
                placeholder="Path"
                onSubmit={(e) =>
                  handleSetPortNumber((e.target as HTMLInputElement).value)
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSetRelativePath((e.target as HTMLInputElement).value);
                  }
                }}
              />
            </span>
          </div>
          <button onClick={openInNewWindow} className="btn fs-22 mr-lt-4">
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              className={styles.browserIcon}
            />
          </button>
        </div>
        <div
          className={`${styles.container} ${
            portUrl != null && portNumber && url
              ? styles.containerWhenActive
              : ""
          }`}
        >
          {portUrl != null && portNumber == null ? (
            <div className={styles.enterPortContainer}>
              Enter port number in the address bar
            </div>
          ) : url != null ? (
            <iframe
              src={url}
              id={IFRAME_ID}
              width={"100%"}
              height={"100%"}
              className={styles.frame}
            ></iframe>
          ) : (
            <div className={styles.enterPortContainer}>
              Something went wrong in creating browser session
            </div>
          )}
        </div>
      </div>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ContentTypes,
  ILesson,
  ILessonPage,
  ILessonPageSection,
} from "../types/lesson";
import useLessonApiClient from "./useLessonApiClient";
import { useLiveLessonFeed } from "./useLiveLessonFeed";

interface UseLessonManager {
  isLoading: boolean;
  isLessonSaved: boolean;
  lesson: ILesson;
  currentPageIndex: number;
  isStreamingCompleted: boolean;
  addPage: () => void;
  savePage: () => Promise<void>;
  updatePage: (updatedPage: ILessonPage) => void;
  addPageSection: () => void;
  deletePageSection: (sectionIndex: number) => void;
  updatePageSection: (
    sectionIndex: number,
    updatedSection: ILessonPageSection
  ) => void;
  updateCurrentPageIndex: (newPageIndex: number) => void;
}

function useLessonManager(lessonId: string): UseLessonManager {
  const lessonRef = React.useRef<ILesson>({
    id: lessonId,
    title: "",
    authorId: "",
    imageUrl: "",
    pages: [{ lessonId, title: "", sections: [], order: 0 }],
  });
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [sectionIDsToDelete, setSectionIDsToDelete] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLessonSaved, setIsLessonSaved] = useState<boolean>(true);
  const history = useHistory();
  const apiClient = useLessonApiClient();
  const { lesson: lessonUpdate, isStreamingCompleted } =
    useLiveLessonFeed(lessonId);

  useEffect(() => {
    updateCurrentPageIndex(lessonRef.current.pages.length - 1);
  }, [lessonRef.current.pages.length]);

  useEffect(() => {
    if (isLessonSaved) {
      setIsLoading(true);
      setSectionIDsToDelete([]);
      apiClient
        .fetchLesson(lessonId)
        .then((lesson) => {
          if (lesson.pages.length === 0) {
            lesson.pages.push({ lessonId, title: "", sections: [], order: 0 });
          }
          lessonRef.current = lesson;
        })
        .catch(() => history.push("/menu"))
        .finally(() => setIsLoading(false));
    }
  }, [isLessonSaved, lessonId]);

  // This should be fixed
  useEffect(() => {
    if (lessonUpdate) {
      lessonRef.current = {
        ...lessonUpdate,
        id: lessonId,
      };
    }
  }, [lessonUpdate]);

  const copyLesson = React.useCallback(
    (originalLesson: ILesson) => {
      try {
        return structuredClone(originalLesson);
      } catch (error) {
        console.log("structured clone throws error", error);
        const copiedLesson = { ...originalLesson };
        copiedLesson.pages = [...copiedLesson.pages];
        copiedLesson.pages[currentPageIndex] = {
          ...copiedLesson.pages[currentPageIndex],
        };
        copiedLesson.pages[currentPageIndex].sections = [
          ...copiedLesson.pages[currentPageIndex].sections,
        ];
        return copiedLesson;
      }
    },
    [currentPageIndex]
  );

  const addPage = React.useCallback(() => {
    const copiedLesson = copyLesson(lessonRef.current);
    copiedLesson.pages.push({
      lessonId: lessonRef.current.id,
      title: "",
      sections: [],
      order:
        lessonRef.current.pages[lessonRef.current.pages.length - 1].order + 1,
    });
    lessonRef.current = copiedLesson;
    setCurrentPageIndex(copiedLesson.pages.length - 1);
  }, []);

  const updatePage = React.useCallback(
    (updatedPage: ILessonPage) => {
      lessonRef.current.pages[currentPageIndex] = updatedPage;
      setIsLessonSaved(false);
    },
    [currentPageIndex]
  );

  const addPageSection = React.useCallback(() => {
    lessonRef.current.pages[currentPageIndex].sections.push({
      showPreview: false,
      order:
        lessonRef.current.pages[currentPageIndex].sections[
          lessonRef.current.pages[currentPageIndex].sections.length - 1
        ]?.order + 1 || 0,
      content: {},
      contentType: ContentTypes.Unknown,
      clarification: {},
    });
  }, [currentPageIndex]);

  const updatePageSection = React.useCallback(
    (sectionIndex: number, updatedSection: ILessonPageSection) => {
      lessonRef.current.pages[currentPageIndex].sections[sectionIndex] =
        updatedSection;
      setIsLessonSaved(false);
    },
    [currentPageIndex]
  );

  const deletePageSection = React.useCallback(
    (sectionIndex: number) => {
      if (!confirm("Are you sure you want to delete?")) {
        return;
      }
      const section =
        lessonRef.current.pages[currentPageIndex].sections[sectionIndex];
      if (section.id) {
        setSectionIDsToDelete([...sectionIDsToDelete, section.id]);
      }
      lessonRef.current.pages[currentPageIndex].sections.splice(
        sectionIndex,
        1
      );
      setIsLessonSaved(false);
    },
    [currentPageIndex]
  );

  const savePage = React.useCallback(async () => {
    if (!isLessonSaved) {
      setIsLoading(true);
      const currentPage = lessonRef.current.pages[currentPageIndex];
      return await apiClient
        .saveLessonPage(currentPage)
        .then(async (currentPage) => {
          await Promise.all(
            sectionIDsToDelete.map(
              async (sectionId) =>
                await apiClient.deleteLessonSection(sectionId)
            )
          );
          return currentPage;
        })
        .then(
          async (savedPage) =>
            await Promise.all(
              currentPage.sections.map(
                async (section) =>
                  await apiClient.saveLessonSection(savedPage.pageId, section)
              )
            )
        )
        .then(() => {
          setIsLessonSaved(true);
        })
        .finally(() => setIsLoading(false));
    }
  }, [currentPageIndex]);

  const updateCurrentPageIndex = React.useCallback((newPageIndex: number) => {
    const pagesLength = lessonRef.current.pages.length;
    setCurrentPageIndex(
      ((newPageIndex % pagesLength) + pagesLength) % pagesLength
    );
  }, []);

  return {
    isLoading,
    isLessonSaved,
    lesson: lessonRef.current,
    currentPageIndex,
    isStreamingCompleted,
    addPage,
    savePage,
    updatePage,
    addPageSection,
    updatePageSection,
    deletePageSection,
    updateCurrentPageIndex,
  };
}

export default useLessonManager;

import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDescendants, type NodeModel } from "@minoru/react-dnd-treeview";
import { type Monaco } from "@monaco-editor/react";
import React from "react";
import { Input } from "../../../Form";
import { createSession } from "../utils/createSession";
import { createSessionDirectory } from "../utils/createSessionDirectory";
import { deleteSessionDirectory } from "../utils/deleteSessionDirectory";
import { moveDirectory } from "../utils/moveDirectory";
import { DeleteIcon } from "../icons/DeleteIcon";
import { FileIcon } from "../icons/FileIcon";
import { FolderIcon } from "../icons/FolderIcon";
import {
  createNewTreeNode,
  getAbsoluteNodePath,
  INodeTypes,
  ROOT_DIRECTORY,
  type TreeNodeDataType,
} from "../../utils";
import NodeIcon from "./NodeIcon";
import styles from "./styles.module.css";
import { useLessonId } from "../../../LessonContext/LessonContext";
import { Tooltip } from "@mui/material";

const TREE_X_OFFSET = 22;

interface INode {
  node: NodeModel<TreeNodeDataType>;
  depth: number;
  isOpen: boolean;
  isDropTarget: boolean;
  monaco: Monaco | undefined;
  treeData: Array<NodeModel<TreeNodeDataType>>;
  openedFileId: string | number | undefined;
  terminalSessionId: string | undefined;
  canAddNode: boolean;
  setError: (error: string) => void;
  setCanAddNode: (canAdd: boolean) => void;
  onClick: (id: NodeModel<TreeNodeDataType>["id"]) => void;
  getPipeHeight: (
    id: string | number,
    treeData: Array<NodeModel<TreeNodeDataType>>
  ) => number;
  open: (id: string | number) => void;
  setTreeData?: (treeData: Array<NodeModel<TreeNodeDataType>>) => void;
  setOpenedFileId?: (fileId: string | number) => void;
  setTerminalSessionId?: (sessionId: string | undefined) => void;
  setRefreshMonaco?: (refresh: boolean) => void;
}

const Node: React.FC<INode> = ({
  node,
  depth,
  isOpen,
  isDropTarget,
  monaco,
  openedFileId,
  open,
  onClick,
  canAddNode,
  setError,
  setCanAddNode,
  treeData,
  getPipeHeight,
  setTreeData,
  setOpenedFileId,
  terminalSessionId,
  setTerminalSessionId,
  setRefreshMonaco,
}) => {
  const indent = depth * TREE_X_OFFSET;
  const lessonId = useLessonId();
  const [showDirectoryIcons, setShowDirectoryIcons] = React.useState(false);
  const [isRenamingFile, setIsRenamingFile] = React.useState(false);
  const relativeParentPath = React.useMemo(
    () => getAbsoluteNodePath(node, treeData),
    [node, treeData, getAbsoluteNodePath]
  );

  const handleToggle = (e: React.MouseEvent): void => {
    e.stopPropagation();
    onClick(node.id);
  };

  const handleAddingNewNode = React.useCallback(
    (e: React.MouseEvent, nodeId: string | number, type: INodeTypes) => {
      e.stopPropagation();
      open(nodeId);
      setCanAddNode(false);
      setTreeData?.([...treeData, createNewTreeNode("", type, nodeId)]);
    },
    [treeData, setTreeData]
  );

  const handleDeleteFile = React.useCallback(
    async (e: React.MouseEvent, nodeId: string | number) => {
      if (!terminalSessionId) {
        return;
      }
      e.stopPropagation();
      // We need to delete the monaco model instance as well as the entry in state.
      if (!node.droppable && monaco) {
        monaco.editor.getModel(monaco.Uri.parse(node.text))?.dispose();
      }
      // TODO refactor this
      const remainingIds = new Set();
      const newTreeData = treeData
        .filter((data) => {
          const remains = data.id !== nodeId && data.parent !== nodeId;
          if (remains) {
            remainingIds.add(data.id);
            console.log(remainingIds);
          }
          return remains;
        })
        .filter(
          (data) =>
            remainingIds.has(data.parent) || data.parent === ROOT_DIRECTORY
        );
      setTreeData?.(newTreeData);

      // Prevent deleting a directory with a yet-to-be specified file name
      // If file name is yet to be specified, it has not been created
      if (node.text.trim()) {
        const isDirectoryDeleted = await deleteSessionDirectory(
          relativeParentPath,
          node.text,
          terminalSessionId
        );
        if (!isDirectoryDeleted) {
          setError("Failed to delete directory");
          console.error("Failed to delete directory");
        }
      } else {
        // If we've deleted a node that wasn't fully created, restore ability to add nodes
        if (!canAddNode) {
          setCanAddNode(true);
        }
      }
    },
    [treeData, setTreeData]
  );

  const handleSubmitTitle = async (
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<HTMLInputElement>
  ): Promise<void> => {
    const { value } = event.target as HTMLInputElement;
    if (value === "") {
      return;
    }

    const allExistingTreeNodes = treeData.filter(
      (dataNode) => dataNode.id !== node.id
    );
    node.text = value;
    setCanAddNode(true);
    setTreeData?.([...allExistingTreeNodes, { ...node }]);
    if (!node.droppable) {
      if (monaco) {
        monaco.editor.createModel(
          "",
          undefined,
          monaco.Uri.parse(relativeParentPath + "/" + value)
        );
      }
      setOpenedFileId?.(node.id);
    }

    if (lessonId == null) {
      setError("Can not create a session when there is no selected lesson");
      console.error(
        "Can not create a session when there is no selected lesson"
      );
      return;
    }

    if (terminalSessionId == null) {
      const sessionId = await createSession(lessonId, node, value);
      setTerminalSessionId?.(sessionId);
    } else {
      const createdDirectory = await createSessionDirectory(
        node,
        relativeParentPath,
        value,
        terminalSessionId
      );

      if (!createdDirectory) {
        setError("Failed to create directory, something went wrong.");
        console.error("Failed to create directory, something went wrong.");
      }
    }
  };

  const handleRenameFile = async (
    event: React.KeyboardEvent
  ): Promise<void> => {
    const { value } = event.target as HTMLInputElement;
    if (value === "") {
      return;
    }
    const previousName = relativeParentPath + "/" + node.text;
    const allExistingTreeNodes = treeData.filter(
      (dataNode) => dataNode.id !== node.id
    );
    node.text = value;
    node.id = relativeParentPath + "/" + value;
    setTreeData?.([...allExistingTreeNodes, { ...node }]);
    if (!node.droppable) {
      if (monaco) {
        const existingModel = monaco.editor.getModel(
          monaco.Uri.parse(previousName)
        );
        monaco.editor.createModel(
          existingModel?.getValue() ?? "",
          undefined,
          monaco.Uri.parse(relativeParentPath + "/" + value)
        );
        existingModel?.dispose();
      }
      setOpenedFileId?.(node.id);
    }
    setIsRenamingFile(false);

    if (lessonId == null) {
      throw new Error(
        "Can not create a session when there is no selected lesson"
      );
    }

    if (terminalSessionId == null) {
      throw new Error("Session Id not found");
    } else {
      const movedDirectory = await moveDirectory(
        previousName,
        relativeParentPath + "/" + value,
        terminalSessionId
      );

      if (!movedDirectory) {
        setError("Failed to rename directory, something went wrong");
        console.error("Failed to rename directory, something went wrong");
      } else {
        setRefreshMonaco?.(true);
      }
    }
  };

  const handleMouseEnter = React.useCallback(() => {
    setShowDirectoryIcons(true);
  }, [showDirectoryIcons]);
  const handleMouseLeave = React.useCallback(() => {
    setShowDirectoryIcons(false);
  }, [showDirectoryIcons]);
  const maybeLanguageExtension = React.useMemo(() => {
    if (node.text) {
      return node.text.split(".").pop();
    }
    return null;
  }, [node.text]);
  return (
    <div
      className={`${styles.nodeWrapper} 
                  ${node.droppable && isDropTarget ? styles.dropTarget : ""}
                  ${node.id === openedFileId ? styles.nodeActive : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ marginInlineStart: indent }}
      onClick={handleToggle}
    >
      <div className={styles.leftIcons}>
        {node.droppable && (
          <div
            className={`${styles.expandIconWrapper} ${
              isOpen ? styles.isOpen : ""
            }`}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#DFE2E0"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5866 5.99969L7.99997 8.58632L5.41332 5.99969C5.15332 5.73969 4.73332 5.73969 4.47332 5.99969C4.21332 6.25969 4.21332 6.67965 4.47332 6.93965L7.5333 9.99965C7.59497 10.0615 7.66823 10.1105 7.7489 10.144C7.82957 10.1775 7.91603 10.1947 8.0033 10.1947C8.09063 10.1947 8.1771 10.1775 8.25777 10.144C8.33837 10.1105 8.41163 10.0615 8.4733 9.99965L11.5333 6.93965C11.7933 6.67965 11.7933 6.25969 11.5333 5.99969C11.2733 5.74635 10.8466 5.73969 10.5866 5.99969Z"
                fill="#DFE2E0"
              />
            </svg>
          </div>
        )}

        <NodeIcon
          type={node.droppable ? (isOpen ? "folder" : "folder-open") : null}
          languageExtension={maybeLanguageExtension ?? undefined}
        />
      </div>

      <div
        className={styles.pipeX}
        style={{ width: depth > 0 ? TREE_X_OFFSET - 9 : 0 }}
      />
      {getDescendants(treeData, node.parent)[0].id === node.id && (
        <div
          className={styles.pipeY}
          style={{
            height: Math.max(0, getPipeHeight(node.parent, treeData) - 8),
          }}
        />
      )}
      <div className={styles.labelGridItem}>
        {node.text !== "" && !isRenamingFile ? (
          node.text
        ) : (
          <Input
            className={styles.fileNameInput}
            autoFocus
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            // onSubmitCapture={handleSubmitTitle}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (isRenamingFile) {
                  void handleRenameFile(e);
                } else {
                  void handleSubmitTitle(e);
                }
              }
            }}
          />
        )}
      </div>

      <div className={styles.rightIcons}>
        {showDirectoryIcons && (
          <Tooltip title={"Rename"}>
            <div
              className={styles.directoryOptionIcon}
              onClick={(e) => {
                setIsRenamingFile(true);
              }}
            >
              <FontAwesomeIcon icon={faPencil} className={styles.button} />
            </div>
          </Tooltip>
        )}
        {node.droppable && showDirectoryIcons && canAddNode && (
          <>
            <Tooltip title={"New File"}>
              <div
                className={styles.directoryOptionIcon}
                onClick={(e) => {
                  if (canAddNode) {
                    handleAddingNewNode(e, node.id, INodeTypes.file);
                  }
                }}
              >
                <FileIcon className={styles.button} color={"#DFE2E0"} />
              </div>
            </Tooltip>

            <Tooltip title={"New Folder"}>
              <div
                className={styles.directoryOptionIcon}
                onClick={(e) => {
                  if (canAddNode) {
                    handleAddingNewNode(e, node.id, INodeTypes.folder);
                  }
                }}
              >
                <FolderIcon className={styles.button} />
              </div>
            </Tooltip>
          </>
        )}
        {showDirectoryIcons && (
          <Tooltip title={"Delete"}>
            <div
              className={styles.directoryOptionIcon}
              onClick={(e) => {
                void handleDeleteFile(e, node.id);
              }}
            >
              <DeleteIcon className={styles.button} />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default Node;

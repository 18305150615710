import {
  faAngleDown,
  faAngleUp,
  faPlus,
  faRefresh,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ChamTerminal } from "../ChamTerminal";
import styles from "./ChamIDEContainer.module.css";
import { Tooltip } from "@mui/material";
import { useTerminalSession } from "../../contexts/TerminalSessionContext/TerminalSessionContext";
import { TerminalMenu } from "./TerminalMenu";
import { useLessonId } from "../../../LessonContext/LessonContext";
import { getUserSessionForLesson } from "../utils/getUserSessionForLesson";
import { useDirectoryTree } from "../../contexts/DirectoryContext/DirectoryContext";
import { getSessionDirectory } from "../utils/getSessionDirectory";

interface TerminalContainerProps {
  setError: (error: string) => void;
}

export const TerminalContainer: React.FC<TerminalContainerProps> = ({
  setError,
}) => {
  const lessonId = useLessonId();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [shouldRestartTerminal, setShouldRestartTerminal] =
    React.useState<boolean>(false);

  const {
    terminalSessionId: sessionId,
    setTerminalSessionId,
    terminals,
    activeTerminal,
    handleAddTerminal,
    terminalDisplayed,
    showTerminal,
    hideTerminal,
  } = useTerminalSession();

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      if (sessionId) {
        setAnchorEl(event.currentTarget);
      }
    },
    [sessionId],
  );
  const { setDirectoryTree } = useDirectoryTree();

  React.useEffect(() => {
    const getSessionId = async (): Promise<void> => {
      if (lessonId != null && setTerminalSessionId != null && !sessionId) {
        const sessionId = await getUserSessionForLesson(lessonId);
        if (sessionId && sessionId !== "null") {
          setTerminalSessionId(sessionId);
        }
      }
    };
    void getSessionId();
  }, [lessonId, setTerminalSessionId, sessionId]);

  React.useEffect(() => {
    const getDir = async (): Promise<void> => {
      if (sessionId && setDirectoryTree) {
        try {
          const directoryTree = await getSessionDirectory(sessionId);
          setDirectoryTree(directoryTree);
        } catch (e: any) {
          setError(e.message);
        }
      }
    };
    void getDir();
  }, [sessionId, setDirectoryTree]);

  const handleToggleTerminal = React.useCallback(() => {
    terminalDisplayed ? hideTerminal() : showTerminal();
  }, [terminalDisplayed, hideTerminal, showTerminal]);

  return (
    <>
      <div className={styles.textInputBottomBar}>
        <div className={styles.heading}>
          <div className={styles.headingContent}>
            {shouldRestartTerminal ? (
              <Tooltip title="Terminal restart in progress">
                <FontAwesomeIcon className={styles.spinner} icon={faSpinner} />
              </Tooltip>
            ) : (
              <Tooltip
                className={styles.minimizeIcon}
                onClick={() => {
                  if (
                    window.confirm(
                      "NOTE: DO NOT RESTART UNLESS YOU NEED TO i.e. YOU RUN INTO STALE FILE HANDLES.\nRestarting the terminal will cause externally installed applications to be cleared.\nIf you do restart, please re-install all externally installed applications.",
                    )
                  ) {
                    setShouldRestartTerminal(true);
                  }
                }}
                title="Restart Terminal"
              >
                <FontAwesomeIcon icon={faRefresh} />
              </Tooltip>
            )}
            TERMINAL
          </div>
          <div className={styles.rightIcon}>
            {terminals && terminals.length > 0 && activeTerminal?.id && (
              <Tooltip
                title={
                  sessionId
                    ? "Terminals"
                    : "Click the plus button to start a session and connect to the terminal"
                }
              >
                <div className={styles.terminalSelect} onClick={handleClick}>
                  {activeTerminal.name.length > 20
                    ? `${activeTerminal.name.slice(0, 20)}...`
                    : activeTerminal.name}
                </div>
              </Tooltip>
            )}
            <Tooltip
              className={styles.minimizeIcon}
              onClick={() => {
                void handleAddTerminal?.();
                showTerminal();
              }}
              title="Add Terminal"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Tooltip>

            <Tooltip
              className={styles.minimizeIcon}
              onClick={handleToggleTerminal}
              title={terminalDisplayed ? "Minimize" : "Maximize"}
            >
              <FontAwesomeIcon
                icon={terminalDisplayed ? faAngleDown : faAngleUp}
              />
            </Tooltip>
          </div>
        </div>
        <ChamTerminal />
      </div>
      <TerminalMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
};

import {
  faCalculator,
  faCloudRain,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useHistory } from "react-router-dom";
import useAILessonCreatorClient from "../../../../hooks/useAILessonCreatorClient";
import ErrorComponent from "../../../common_components/ErrorComponent";
import { LoadingComponentFullScreen } from "../../../common_components/LoadingComponent";
import styles from "./LessonCreationMenu.module.css";
import { BuildExampleCard } from "../BuildExampleCard/BuildExampleCard";
import PromptInput from "../../../common_components/PromptInput/PromptInput";
import { branding } from "../../../../config/branding";

export const LessonCreationMenu: React.FC = () => {
  const history = useHistory();
  const [error, setError] = React.useState("");
  const { loading, generateSectionContentFromPrompt } =
    useAILessonCreatorClient();

  const handleSubmitPrompt = React.useCallback(async (prompt: string) => {
    await generateSectionContentFromPrompt(prompt).then((lessonId: string) => {
      history.push(`/lesson/${lessonId}`);
    });
  }, []);

  const exampleCards = [
    {
      icon: faListCheck,
      appName: "A to-do list app",
      runFunction: async () =>
        await handleSubmitPrompt("Build a todo list app"),
      subText:
        "Generate a lesson to teach how to build a tod-do list web application.",
    },
    {
      icon: faCloudRain,
      appName: "Weather app",
      runFunction: async () => await handleSubmitPrompt("Build a weather app"),
      subText: "Generate a lesson to teach how to build a weather application.",
    },
    {
      icon: faCalculator,
      appName: "Simple calculator",
      runFunction: async () =>
        await handleSubmitPrompt("Build a simple calculator app"),
      subText:
        "Generate a lesson to teach how to build a simple calculator web application.",
    },
  ];

  return (
    <div className={styles.container}>
      {loading && <LoadingComponentFullScreen />}
      {error && (
        <ErrorComponent
          title="Lesson generation failed."
          close={() => setError("")}
        >
          {error}
        </ErrorComponent>
      )}
      <div className={styles.aiContainer}>
        <div className={styles.aiBody}>
          <div className={styles.gridTitle}>
            Hello, what are you building{" "}
            <span className={styles.todayText}>today?</span>
          </div>

          <div className={styles.inputGroup}>
            <PromptInput
              handleSendMessage={(message) => {
                void handleSubmitPrompt(message);
              }}
              className={styles.promptInput}
              placeholder={"Ask " + branding.name + "..."}
            />

            <div className={styles.examples}>
              {exampleCards.map((card, index) => (
                <BuildExampleCard
                  key={index}
                  icon={card.icon}
                  appName={card.appName}
                  runFunction={card.runFunction}
                  subText={card.subText}
                  className={styles.exampleCard}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import styles from "./ClarificationChat.module.css";
import { Clarification } from "../../../../types/clarifications";
import { useClarificationChat } from "../../../common_components/ChamIDE/contexts/ClarificationChatContext/ClarificationChatContext";
import { Collapsible } from "../Collapsible";
import useAILessonCreatorClient from "../../../../hooks/useAILessonCreatorClient";
import { useLessonId } from "../../../common_components/LessonContext/LessonContext";
import { ClarificationType } from "../../../../__generated__/graphql";
import { useViewContext } from "../../../common_components/ChamIDE/contexts/ViewContext/ViewContext";
import {
  SidebarViewState,
  ViewState,
} from "../../../common_components/LessonLayout/types";
import {
  DeviceType,
  useResponsiveness,
} from "../../../../hooks/useResponsiveness";

export const ClarificationsView: React.FC = () => {
  const lessonId = useLessonId();
  const {
    activeChatId,
    codeChatId,
    setCodeChatId,
    setActiveChatId,
    fetchClarificationsTrigger,
  } = useClarificationChat();
  const { getUserClarificationsForLesson } = useAILessonCreatorClient();
  const { setCurrentView, setCurrentSidebarView } = useViewContext();
  const [clarifications, setClarifications] = React.useState<Clarification[]>(
    []
  );
  const { deviceType } = useResponsiveness();

  const handleOpenChat = React.useCallback(
    (idx: string) => {
      setActiveChatId?.(idx);
      setCurrentView(ViewState.ClarificationChat);
      if (deviceType === DeviceType.Mobile) {
        setCurrentSidebarView(SidebarViewState.None);
      }
    },
    [deviceType]
  );

  const handleNewChat = React.useCallback(() => {
    setActiveChatId?.("new");
    setCurrentView(ViewState.ClarificationChat);
    if (deviceType === DeviceType.Mobile) {
      setCurrentSidebarView(SidebarViewState.None);
    }
  }, [deviceType]);

  React.useEffect(() => {
    if (lessonId) {
      void getUserClarificationsForLesson(lessonId).then((clars) => {
        setClarifications(
          clars.filter(
            (clar) => clar.clarificationType !== ClarificationType.Editor
          )
        );
        setCodeChatId?.(
          clars.find(
            (clar) => clar.clarificationType === ClarificationType.Editor
          )?.clarificationId
        );
      });
    }
  }, [lessonId, fetchClarificationsTrigger]);

  return (
    <div className={styles.container}>
      <div className={styles.clarificationsContainer}>
        <div className={styles.clarificationNew} onClick={handleNewChat}>
          + New
        </div>
        <div className={styles.workspaceClarifications}>
          <Collapsible title="Workspace">
            <div className={styles.clarifications}>
              {clarifications.map((clarification) => (
                <div
                  key={clarification.clarificationId}
                  className={`${styles.clarification} ${clarification.clarificationId === activeChatId ? styles.activeChat : ""}`}
                  onClick={() => handleOpenChat(clarification.clarificationId)}
                >
                  {clarification.clarificationQuestion}
                </div>
              ))}
            </div>
          </Collapsible>
        </div>
        {codeChatId && (
          <div className={styles.TerminalClarifications}>
            <Collapsible title="Terminal">
              <div className={styles.clarifications}>
                <div
                  key={codeChatId}
                  className={`${styles.clarification} ${codeChatId === activeChatId ? styles.activeChat : ""}`}
                  onClick={() => handleOpenChat(codeChatId)}
                >
                  IDE Assist
                </div>
              </div>
            </Collapsible>
          </div>
        )}
      </div>
    </div>
  );
};

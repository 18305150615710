import { Tooltip } from "@mui/material";
import React from "react";
import { useDirectoryTree } from "../../contexts/DirectoryContext/DirectoryContext";
import { useMonaco } from "../../contexts/MonacoContext/MonacoContext";
import { useTerminalSession } from "../../contexts/TerminalSessionContext/TerminalSessionContext";
import { INodeTypes, createNewTreeNode } from "../../utils";
import { FileIcon } from "../icons/FileIcon";
import { FolderIcon } from "../icons/FolderIcon";
import { RefreshIcon } from "../icons/RefreshIcon";
import { getSessionDirectory } from "../utils/getSessionDirectory";
import { FolderTree } from "./FolderTree";
import styles from "./styles.module.css";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useViewContext } from "../../contexts/ViewContext/ViewContext";
import { SidebarViewState, ViewState } from "../../../LessonLayout/types";
import {
  DeviceType,
  useResponsiveness,
} from "../../../../../hooks/useResponsiveness";

interface IWorkspaceSideBar {
  setError: (error: string) => void;
  width?: number;
}

export const WorkspaceSideBar: React.FC<IWorkspaceSideBar> = ({
  setError,
  width,
}) => {
  // const lessonId = useLessonId();
  const { openedFileId, setOpenedFileId, directoryTree, setDirectoryTree } =
    useDirectoryTree();
  const { terminalSessionId, setTerminalSessionId } = useTerminalSession();
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [canAddNode, setCanAddNode] = React.useState(true);
  const { setCurrentView, setCurrentSidebarView } = useViewContext();
  const { deviceType } = useResponsiveness();

  const { monaco, setRefreshMonaco } = useMonaco();

  const handleAddNewDir = React.useCallback(
    (type: INodeTypes) => {
      if (directoryTree != null) {
        setCanAddNode(false);
        setDirectoryTree?.([...directoryTree, createNewTreeNode("", type)]);
      }
    },
    [directoryTree, setDirectoryTree]
  );

  const handleRefreshDirectoryTree =
    React.useCallback(async (): Promise<void> => {
      setIsRefreshing(true);
      if (terminalSessionId && setDirectoryTree) {
        try {
          const directoryTree = await getSessionDirectory(terminalSessionId);
          setDirectoryTree(directoryTree);
        } catch (e: any) {
          setError(e.error);
        }
      }
      setIsRefreshing(false);
    }, [terminalSessionId, setDirectoryTree]);

  const onOpenFile = React.useCallback(() => {
    if (deviceType === DeviceType.Mobile) {
      setCurrentSidebarView(SidebarViewState.None);
      setCurrentView(ViewState.IDE);
    }
  }, [setCurrentView, deviceType]);

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarContainer}>
        <div className={styles.topbar}>
          Files
          <div className={styles.workspaceRightIcons}>
            {canAddNode && (
              <Tooltip title={"New File"}>
                <div
                  className={styles.directoryOptionIcon}
                  onClick={(e) => {
                    if (canAddNode) {
                      handleAddNewDir(INodeTypes.file);
                    }
                  }}
                >
                  <FileIcon
                    className={styles.button}
                    color={"#DFE2E0"}
                    height={"12px"}
                  />
                </div>
              </Tooltip>
            )}
            {canAddNode && (
              <Tooltip title={"New Folder"}>
                <div
                  className={styles.directoryOptionIcon}
                  onClick={(e) => {
                    if (canAddNode) {
                      handleAddNewDir(INodeTypes.folder);
                    }
                  }}
                >
                  <FolderIcon
                    className={styles.button}
                    color={"#DFE2E0"}
                    height={"12px"}
                  />
                </div>
              </Tooltip>
            )}
            <Tooltip title={"Refresh Directory"}>
              <div
                className={styles.directoryOptionIcon}
                onClick={(e) => {
                  void handleRefreshDirectoryTree();
                }}
              >
                {isRefreshing ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className={"fa-spin"}
                    color="DFE2E0"
                    style={{ padding: "5px" }}
                    height={"12px"}
                  />
                ) : (
                  <RefreshIcon className={styles.button} height={"12px"} />
                )}
              </div>
            </Tooltip>
          </div>
        </div>
        <FolderTree
          setError={setError}
          setOpenedFileId={setOpenedFileId}
          openedFileId={openedFileId}
          onOpenFile={onOpenFile}
          treeData={directoryTree}
          setTreeData={setDirectoryTree}
          monaco={monaco}
          terminalSessionId={terminalSessionId}
          setTerminalSessionId={setTerminalSessionId}
          canAddNode={canAddNode}
          setRefreshMonaco={setRefreshMonaco}
          setCanAddNode={setCanAddNode}
          handleAddNewDir={handleAddNewDir}
        />
      </div>
    </div>
  );
};

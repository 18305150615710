import React from "react";
import { ChamEditor } from "../ChamEditor/ChamEditor";
import styles from "./ChamIDEContainer.module.css";
import { IdeContainer } from "./types";
import { useTerminalHighlight } from "../../contexts/TerminalHighlightContext";
import { TerminalContainer } from "./TerminalContainer";
import {
  DeviceType,
  useResponsiveness,
} from "../../../../../hooks/useResponsiveness";
import { useTerminalSession } from "../../contexts/TerminalSessionContext/TerminalSessionContext";

export const DesktopIdeContainer: React.FC<IdeContainer> = ({
  setError,
  toggleShowChamBrowser,
}) => {
  const { isTerminalHighlighted } = useTerminalHighlight();
  const { deviceType } = useResponsiveness();
  const { terminalDisplayed } = useTerminalSession();

  return (
    <>
      <div
        className={`${styles.textInputContainer} ${styles.textInputContainerFull}`}
      >
        <div
          className={
            !terminalDisplayed
              ? styles.editorSectionFull
              : styles.editorSectionHalf
          }
        >
          {/* To avoid ResizeObserver bug, comment out below line and uncomment line after it */}
          <ChamEditor toggleShowChamBrowser={toggleShowChamBrowser} />
          {/* {!isDragging ? <ChamEditor /> : <></>} */}
        </div>

        {deviceType === DeviceType.Desktop && (
          <div
            className={`
          ${
            terminalDisplayed
              ? styles.terminalContainerOpen
              : styles.terminalContainerCollapsed
          }
          ${isTerminalHighlighted ? styles.isTerminalHighlighted : ""}
        `}
          >
            <TerminalContainer setError={setError} />
          </div>
        )}
      </div>
    </>
  );
};

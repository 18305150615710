import React from "react";
import styles from "./LoadingComponent.module.css";
import IconLogo from "../Logo/icon-logo";
import DecentralHubLogo from "../../../assets/decentralhub-logo.png";
import { branding } from "../../../config/branding";

export const LoadingComponentContained = (): JSX.Element => {
  return (
    <div className={styles.containerContained}>
      <IconLogo />
      <span className={styles.title}>BuildQL</span>
    </div>
  );
};

export const LoadingComponentFullScreen = (): JSX.Element => {
  return branding.name === "DecentralHub" ? (
    <div className={styles.container}>
      <img src={DecentralHubLogo} style={{ width: "150px" }} />
    </div>
  ) : (
    <div className={styles.container}>
      <IconLogo height="150px" />
      <span className={styles.title}>BuildQL</span>
    </div>
  );
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { Action } from "..";
import { createFile } from "../../../../common_components/ChamIDE/components/utils/createSessionFile";
import { useTerminalSession } from "../../../../common_components/ChamIDE/contexts/TerminalSessionContext/TerminalSessionContext";
import { useDirectoryTree } from "../../../../common_components/ChamIDE/contexts/DirectoryContext/DirectoryContext";
import { getSessionDirectory } from "../../../../common_components/ChamIDE/components/utils/getSessionDirectory";
import { getFileNameAndPath } from "./utils";

export interface OpenAction {
  id: string;
  actionType: "OPEN";
  fileName: string;
}

export enum OpenState {
  IGNORED = "IGNORED",
  REQUESTED = "REQUESTED",
  DIRECTORY_RELOADED = "DIRECTORY_RELOADED",
  FILE_DISPLAYED = "FILE_DISPLAYED",
  DONE = "DONE",
  DONE_WITH_EXCEPTION = "DONE_WITH_EXCEPTION",
}

export const useOpenActionHandler = (
  action: Action,
): { openState: OpenState; activeAction: OpenAction | undefined } => {
  const [openState, setOpenState] = useState<OpenState>(OpenState.IGNORED);
  const { terminalSessionId } = useTerminalSession();
  const { directoryTree, setDirectoryTree, setOpenedFileId } =
    useDirectoryTree();

  const activeAction = useMemo<OpenAction | undefined>(() => {
    if (action.actionType === "OPEN") {
      return action;
    } else {
      setOpenState(OpenState.IGNORED);
    }
  }, [action]);

  // Utility functions
  const reloadDirectory = useCallback(async () => {
    if (terminalSessionId && setDirectoryTree) {
      const newDirectoryTree = await getSessionDirectory(terminalSessionId);
      setDirectoryTree(newDirectoryTree);
    }
  }, [terminalSessionId, setDirectoryTree]);

  // State management
  useEffect(() => {
    if (activeAction) {
      setOpenState(OpenState.REQUESTED);
    }
  }, [activeAction]);

  useEffect(() => {
    if (activeAction && openState === OpenState.REQUESTED) {
      const { filePath, fileName } = getFileNameAndPath(activeAction.fileName);
      void createFile(terminalSessionId ?? "", filePath, fileName)
        .then(reloadDirectory)
        .then(() => {
          setOpenState(OpenState.DIRECTORY_RELOADED);
        })
        .catch(() => {
          setOpenState(OpenState.DONE_WITH_EXCEPTION);
        });
    }
  }, [openState, activeAction]);

  useEffect(() => {
    // Todo consider checking if directory tree changed to what you wanted
    if (activeAction && openState === OpenState.DIRECTORY_RELOADED) {
      setOpenedFileId?.(activeAction.fileName.replace("/workspace/", "root/"));
      setOpenState(OpenState.FILE_DISPLAYED);
    }
  }, [openState, activeAction, directoryTree]);

  useEffect(() => {
    if (activeAction && openState === OpenState.FILE_DISPLAYED) {
      setOpenState(OpenState.DONE);
    }
  }, [openState]);

  return {
    openState,
    activeAction,
  };
};

import React, { useEffect, useRef } from "react";
import styles from "./LessonSectionPreview.module.css";
import { ILessonPageSection } from "../../../types/lesson";
import { useTerminalHighlight } from "../ChamIDE/contexts/TerminalHighlightContext";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { MarkdownPreviewCodeBlock } from "./MarkdownPreviewCodeBlock";
import parse from "html-react-parser";
import { useClarificationChat } from "../ChamIDE/contexts/ClarificationChatContext/ClarificationChatContext";
import { useViewContext } from "../ChamIDE/contexts/ViewContext/ViewContext";
import { ViewState } from "../LessonLayout/types";

interface IRichTextPreviewProps {
  section: ILessonPageSection;
  pageId: string;
  lessonId: string;
}

function MarkdownPreview({
  section,
  pageId,
  lessonId,
}: IRichTextPreviewProps): React.JSX.Element {
  const { setIsTerminalHighlighted } = useTerminalHighlight();
  const contentRef = useRef<HTMLDivElement>(null);
  const { setActiveChatId } = useClarificationChat();
  const handleTerminalHighlight = (): void => {
    setIsTerminalHighlighted?.(true);
    console.log("Terminal highlighted");
  };
  const content = section.content.markdown?.content;
  const { setCurrentView } = useViewContext();

  const handleTerminalRemoveHighlight = (): void => {
    setIsTerminalHighlighted?.(false);
  };

  const wrapKeywordsWithSpans = (htmlString: string): string => {
    const terminalRegex = /(chamterminal)/gi;
    const workspaceRegex = / workspace /gi;

    let result = htmlString.replace(
      terminalRegex,
      (match) =>
        `<span class="terminal-highlight" style="color: #39a76b; font-weight: bold; cursor: pointer;">${match}</span>`
    );

    result = result.replace(
      workspaceRegex,
      (match) =>
        `<span class="workspace-highlight" style="color: #ff9800; font-weight: bold; cursor: pointer;">${match}</span>`
    );

    return result;
  };

  useEffect(() => {
    const contentElement = contentRef.current;

    if (!contentElement) return;

    const handleEvent = (event: Event): void => {
      const target = event.target as HTMLElement;
      if (target.classList.contains("terminal-highlight")) {
        if (event.type === "mouseenter" || event.type === "touchstart") {
          handleTerminalHighlight();
        } else if (event.type === "mouseleave" || event.type === "touchend") {
          handleTerminalRemoveHighlight();
        }
      }
    };

    contentElement.addEventListener("mouseenter", handleEvent, true);
    contentElement.addEventListener("mouseleave", handleEvent, true);
    contentElement.addEventListener("touchstart", handleEvent, true);
    contentElement.addEventListener("touchend", handleEvent, true);

    return () => {
      contentElement.removeEventListener("mouseenter", handleEvent, true);
      contentElement.removeEventListener("mouseleave", handleEvent, true);
      contentElement.removeEventListener("touchstart", handleEvent, true);
      contentElement.removeEventListener("touchend", handleEvent, true);
    };
  }, []);

  const renderedComponent = React.useMemo(() => {
    if (section.clarification.id) {
      if (section.clarification.summary) {
        return (
          <div
            onClick={() => {
              setActiveChatId?.(section.clarification.id);
              setCurrentView(ViewState.ClarificationChat);
            }}
            className={styles.clarificationSummary}
          >
            {parse(section.clarification.summary)}
          </div>
        );
      }
    }
  }, [section.clarification, section]);

  if (!content) {
    console.error("content not found");
    return <div></div>;
  }

  return (
    <>
      <div
        className={styles.markdownPreview}
        ref={contentRef}
        onMouseLeave={handleTerminalRemoveHighlight}
        onTouchCancel={handleTerminalRemoveHighlight}
      >
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={{
            // Use this temporarily
            code: MarkdownPreviewCodeBlock,
          }}
        >
          {wrapKeywordsWithSpans(content)}
        </ReactMarkdown>
      </div>
      {renderedComponent}
    </>
  );
}

export default MarkdownPreview;

import { useEffect, useMemo, useState } from "react";
import { Action, EmptyAction } from "..";
import {
  OpenAction,
  OpenState,
  useOpenActionHandler,
} from "./useOpenActionHandler";
import { useDirectoryTree } from "../../../../common_components/ChamIDE/contexts/DirectoryContext/DirectoryContext";
import { useTerminalSession } from "../../../../common_components/ChamIDE/contexts/TerminalSessionContext/TerminalSessionContext";
import { getFileNameAndPath } from "./utils";
import { useMonaco } from "../../../../common_components/ChamIDE/contexts/MonacoContext/MonacoContext";

export interface WriteAction {
  id: string;
  actionType: "WRITE";
  fileName: string;
  content: string;
}

export enum WriteState {
  IGNORED = "IGNORED",
  REQUESTED = "REQUESTED",
  OPEN_TRIGGERED = "OPEN_TRIGGERED",
  WRITE_TRIGGERED = "WRITE_TRIGGERED",
  WRITE_COMPLETE = "WRITE_COMPLETE",
  DONE = "DONE",
}

const EMPTY_FALLBACK: EmptyAction = { actionType: "EMPTY", id: "" };

export const useWriteActionHandler = (
  action: Action,
): { writeState: WriteState; activeAction: WriteAction | undefined } => {
  const [writeState, setWriteState] = useState<WriteState>(WriteState.IGNORED);
  const [openAction, setOpenAction] = useState<OpenAction>();
  const { terminalSessionId } = useTerminalSession();
  const { writeToDirectory, directoryTree } = useDirectoryTree();
  const { setRefreshMonaco, refreshMonaco } = useMonaco();

  const activeAction = useMemo<WriteAction | undefined>(() => {
    if (action.actionType === "WRITE") {
      return action;
    } else {
      setWriteState(WriteState.IGNORED);
    }
  }, [action]);

  useEffect(() => {
    if (activeAction) {
      setWriteState(WriteState.REQUESTED);
    }
  }, [activeAction]);

  useEffect(() => {
    if (activeAction && writeState === WriteState.REQUESTED) {
      setOpenAction({
        id: activeAction.id,
        fileName: activeAction.fileName,
        actionType: "OPEN",
      });
      setWriteState(WriteState.OPEN_TRIGGERED);
    }
  }, [activeAction, writeState]);

  const { openState, activeAction: openStateAction } = useOpenActionHandler(
    openAction ?? EMPTY_FALLBACK,
  );

  useEffect(() => {
    if (
      activeAction &&
      writeState === WriteState.OPEN_TRIGGERED &&
      openState === OpenState.DONE &&
      openStateAction?.id === activeAction?.id
    ) {
      const { filePath, fileName } = getFileNameAndPath(activeAction.fileName);
      // Set write state before to ensure, write triggered state is always triggered before directory change
      setWriteState(WriteState.WRITE_TRIGGERED);
      writeToDirectory?.(
        activeAction.content,
        filePath,
        fileName,
        terminalSessionId,
      );
    }
  }, [openState, writeState, openStateAction, activeAction]);

  useEffect(() => {
    if (activeAction && writeState === WriteState.WRITE_TRIGGERED) {
      setRefreshMonaco?.(true);
      setWriteState(WriteState.WRITE_COMPLETE);
    }
  }, [directoryTree]);

  useEffect(() => {
    if (refreshMonaco === false && writeState === WriteState.WRITE_COMPLETE) {
      setWriteState(WriteState.DONE);
    }
  }, [refreshMonaco, writeState]);

  return {
    writeState,
    activeAction,
  };
};

import React from "react";
import useAILessonCreatorClient from "../../../../hooks/useAILessonCreatorClient";
import { useClarificationChat } from "../../../common_components/ChamIDE/contexts/ClarificationChatContext/ClarificationChatContext";
import { useParams } from "react-router-dom";
import { usePopoverContext } from "../../../common_components/ChamIDE/contexts/PopoverContext/PopoverContext";
import { ClarificationType } from "../../../../__generated__/graphql";
import { useViewContext } from "../../../common_components/ChamIDE/contexts/ViewContext/ViewContext";
import {
  SidebarViewState,
  ViewState,
} from "../../../common_components/LessonLayout/types";

export interface AIPopoverResults {
  handleTerminalClarifyWithCham: (
    text: string,
    question?: string
  ) => Promise<void>;
  handleLessonClarifyWithCham: (
    text: string,
    question?: string,
    chatId?: string
  ) => Promise<string | undefined>;
}

export const useAIPopover = (): AIPopoverResults => {
  const { clarifyTextWithChamAI } = useAILessonCreatorClient();
  const {
    setCodeChatId,
    codeChatId,
    setActiveChatId,
    fetchClarificationsTrigger,
    setFetchClarificationsTrigger,
  } = useClarificationChat();
  const { setShowPopover, popoverRef } = usePopoverContext();
  const { lessonId } = useParams<{ lessonId: string }>();
  const { setCurrentView, setCurrentSidebarView } = useViewContext();

  // Remove popover when document is clicked
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        window.getSelection() == null ||
        (popoverRef?.current &&
          !popoverRef.current.contains(event.target as Node))
      ) {
        setShowPopover?.(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverRef]);

  const handleTerminalClarifyWithCham = React.useCallback(
    async (text: string, question?: string) => {
      if (text && lessonId && question) {
        const clarification = await clarifyTextWithChamAI(
          text,
          lessonId,
          undefined,
          question + " " + text,
          ClarificationType.Editor,
          codeChatId
        );
        setFetchClarificationsTrigger?.((fetchClarificationsTrigger ?? 0) + 1);
        if (!codeChatId) {
          setCodeChatId?.(clarification);
        }
        setActiveChatId?.(clarification);
        setCurrentView(ViewState.ClarificationChat);
      }
      setShowPopover?.(false);
    },
    [
      lessonId,
      codeChatId,
      clarifyTextWithChamAI,
      fetchClarificationsTrigger,
      setFetchClarificationsTrigger,
      setActiveChatId,
      setCodeChatId,
      setShowPopover,
    ]
  );

  const handleLessonClarifyWithCham = React.useCallback(
    async (text: string, question?: string, chatId?: string) => {
      if (text && lessonId && question) {
        const clarification = await clarifyTextWithChamAI(
          text,
          lessonId,
          undefined,
          question,
          ClarificationType.Content,
          chatId
        );
        setFetchClarificationsTrigger?.((fetchClarificationsTrigger ?? 0) + 1);
        setActiveChatId?.(clarification);
        setCurrentSidebarView(SidebarViewState.Clarifications);
        setShowPopover?.(false);

        return clarification;
      }
      setShowPopover?.(false);
    },
    [
      lessonId,
      clarifyTextWithChamAI,
      fetchClarificationsTrigger,
      setFetchClarificationsTrigger,
    ]
  );

  return {
    handleTerminalClarifyWithCham,
    handleLessonClarifyWithCham,
  };
};

import { useEffect, useState } from "react";

export enum DeviceType {
  Mobile = "mobile",
  Desktop = "desktop",
}

interface UseResponsiveness {
  deviceType: DeviceType;
  windowWidth: number;
}

export const useResponsiveness = (): UseResponsiveness => {
  const [deviceType, setDeviceType] = useState(DeviceType.Desktop);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = (): void => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth <= 1024) {
        setDeviceType(DeviceType.Mobile);
      } else {
        setDeviceType(DeviceType.Desktop);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { deviceType, windowWidth };
};

import { useCallback, useEffect } from "react";
import StompJS from "stompjs";
import { Action } from "..";

export interface SessionResponse {
  message: string;
  seqNo: number;
}

export const useActionInstructorSession = (
  stompClient: StompJS.Client | undefined,
  isStompConnected: boolean,
  lessonId: string,
  pageId: string,
  setAction: (output: Action) => void,
  requestId: string,
): {
  startSession: () => void;
} => {
  const setupStompClientSubscriptions = useCallback(
    (stompClient: StompJS.Client, requestId: string) => {
      const handleResponse = (response: StompJS.Message): void => {
        setAction(JSON.parse(response.body));
      };
      stompClient.subscribe("/actions/" + requestId, handleResponse);
    },
    [setAction],
  );

  const startSession = useCallback((): void => {
    console.log("start session called");
    stompClient?.send(
      "/action/start",
      {},
      JSON.stringify({
        lessonId,
        pageId,
        requestId,
      }),
    );
  }, [lessonId, pageId, stompClient, requestId]);

  useEffect(() => {
    if (stompClient && isStompConnected && requestId) {
      setupStompClientSubscriptions(stompClient, requestId);
    }
  }, [isStompConnected, lessonId, pageId, requestId, stompClient]);

  return {
    startSession,
  };
};

import React from "react";
import styles from "./Chat.module.css";
import useAILessonCreatorClient from "../../../../../hooks/useAILessonCreatorClient";
import { ClarificationWithMessage } from "../../../../../types/clarifications";
import { useLessonId } from "../../../../common_components/LessonContext/LessonContext";
import { ChatMessageView } from "./ChatMessageView";
import { useClarificationChat } from "../../../../common_components/ChamIDE/contexts/ClarificationChatContext/ClarificationChatContext";
import { ClarificationType } from "../../../../../__generated__/graphql";
import PromptInput from "../../../../common_components/PromptInput/PromptInput";

interface IChatView {
  chatId: string;
  clarification: ClarificationWithMessage | undefined;
  isClarificationLoading: boolean;
}
export const ChatView: React.FC<IChatView> = ({
  chatId,
  clarification,
  isClarificationLoading,
}) => {
  const { clarifyTextWithChamAI } = useAILessonCreatorClient();
  const {
    setActiveChatId,
    setFetchClarificationsTrigger,
    fetchClarificationsTrigger,
  } = useClarificationChat();

  const lessonId = useLessonId();

  const handleSendMessage = React.useCallback(
    async (prompt: string) => {
      if (!lessonId) {
        return;
      }
      const activeId = await clarifyTextWithChamAI(
        "",
        lessonId,
        undefined,
        prompt,
        chatId === "new" ? ClarificationType.Content : undefined,
        chatId !== "new" ? chatId : undefined
      );
      setActiveChatId?.(activeId);
      if (chatId === "new") {
        setFetchClarificationsTrigger?.((fetchClarificationsTrigger ?? 0) + 1);
      }
    },
    [
      lessonId,
      chatId,
      fetchClarificationsTrigger,
      setFetchClarificationsTrigger,
    ]
  );

  return (
    <div className={styles.chatContainer}>
      <div className={styles.chatTopSection}>
        <div className={styles.clarificationChatTitle}>
          <h2>Clarifications</h2>
        </div>
      </div>
      {lessonId && (
        <ChatMessageView
          chatId={chatId}
          clarification={clarification}
          isLoading={isClarificationLoading}
          lessonId={lessonId}
          handleSendMessage={(input: string) => {
            void handleSendMessage(input);
          }}
        />
      )}
      <PromptInput
        handleSendMessage={(message) => {
          void handleSendMessage(message);
        }}
      />
    </div>
  );
};

import React from "react";
import useLessonManager from "../../../../hooks/useLessonManager";
import { useUserApiClient } from "../../../../hooks/useUserApiClient";
import PageMenu from "../../../LessonCreation/components/PageMenu";
import styles from "./LessonContainer.module.css";
import { LessonSectionRender } from "./LessonSectionRender";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { ChatView } from "../Clarifications/ChatView";
import { useClarificationChat } from "../../../common_components/ChamIDE/contexts/ClarificationChatContext/ClarificationChatContext";
import { AIClarificationPopover } from "../AIClarificationPopover";
import { useAIPopover } from "./useAIPopover";
import GithubComponent from "../../../common_components/LessonLayout/GithubComponent/GithubComponent";
import { useTerminalSession } from "../../../common_components/ChamIDE/contexts/TerminalSessionContext/TerminalSessionContext";
import { createSession } from "../../../common_components/ChamIDE/components/utils/createSession";
import { getUserSessionForLesson } from "../../../common_components/ChamIDE/components/utils/getUserSessionForLesson";
import { useViewContext } from "../../../common_components/ChamIDE/contexts/ViewContext/ViewContext";
import { ViewState } from "../../../common_components/LessonLayout/types";
import useAILessonCreatorClient from "../../../../hooks/useAILessonCreatorClient";
import { ClarificationWithMessage } from "../../../../types/clarifications";

export const LessonContainer = ({
  lessonId,
}: {
  lessonId: string;
}): JSX.Element => {
  const {
    lesson,
    currentPageIndex,
    updateCurrentPageIndex,
    isLoading: isLessonLoading,
    isStreamingCompleted,
  } = useLessonManager(lessonId);
  const {
    saveUserPageCompleted,
    removeUserPageCompleted,
    enrollLesson,
    fetchUsersLessonPagesCompleted,
  } = useUserApiClient();
  const { handleLessonClarifyWithCham } = useAIPopover();
  const { terminalSessionId, setTerminalSessionId } = useTerminalSession();
  const { currentView } = useViewContext();
  const [pagesCompleted, setPagesCompleted] = React.useState<string[]>([]);
  const [isPageCompleted, setIsPageCompleted] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [highlightedText, setHighlightedText] = React.useState<string>();
  const [popoverPosition, setPopoverPosition] = React.useState({
    top: 0,
    left: 0,
  });
  const { getClarification } = useAILessonCreatorClient();
  const [isShowingPopover, setIsShowingPopover] = React.useState(false);
  const [clarificationData, setClarificationData] = React.useState<{
    loading: boolean;
    data?: ClarificationWithMessage;
  }>({
    loading: true,
    data: undefined,
  });

  const handleTextHighlight = (event: any): void => {
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      setIsShowingPopover(true);
      const selectedText = selection.toString().trim();
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      setPopoverPosition({
        top: rect.top - window.scrollY - 110,
        left: rect.left,
      });
      setHighlightedText(selectedText);
    }
  };

  const { activeChatId } = useClarificationChat();
  const handleSubmitClarification = React.useCallback(
    async (question: string) => {
      if (!highlightedText) {
        return;
      }
      await handleLessonClarifyWithCham(highlightedText, question);
      setIsShowingPopover(false);
    },
    [highlightedText]
  );
  React.useEffect(() => {
    void fetchUsersLessonPagesCompleted(lessonId).then((res) => {
      if (res.pagesCompleted != null) {
        setPagesCompleted(res.pagesCompleted);
      }
    });
  }, [lessonId]);

  React.useEffect(() => {
    const pageId = lesson.pages[currentPageIndex].id;
    if (pageId) {
      setIsPageCompleted(pagesCompleted.includes(pageId));
    }
  }, [pagesCompleted, currentPageIndex, lesson.pages[currentPageIndex].id]);

  const toggleMarkPageCompleted = React.useCallback(async () => {
    setIsLoading(true);
    const pageId = lesson.pages[currentPageIndex].id;
    if (pageId) {
      if (isPageCompleted) {
        const response = await removeUserPageCompleted(lesson.id, pageId);
        if (response.success) {
          setPagesCompleted(
            pagesCompleted.filter((pageCompleted) => pageCompleted !== pageId)
          );
        }
        setIsLoading(false);
        return response;
      } else {
        const response = await saveUserPageCompleted(lesson.id, pageId);
        // HACKY HACK: We need an enrolment view, in the mean time we will enroll users to the course when they click on mark complete
        await enrollLesson(lesson.id);
        if (response.success) {
          setPagesCompleted([...pagesCompleted, pageId]);
        }
        setIsLoading(false);
        return response;
      }
    }
    setIsLoading(false);
    return { success: false, error: "Failed to get page id" };
  }, [currentPageIndex, lesson, isPageCompleted]);

  React.useEffect(() => {
    const getSessionId = async (): Promise<void> => {
      if (
        lessonId != null &&
        setTerminalSessionId != null &&
        !terminalSessionId
      ) {
        let sessionId = await getUserSessionForLesson(lessonId);
        if (!sessionId) {
          sessionId = await createSession(lessonId);
        }
        if (sessionId && sessionId !== "null") {
          setTerminalSessionId(sessionId);
        }
      }
    };
    void getSessionId();
  }, [lessonId, setTerminalSessionId, terminalSessionId]);

  React.useEffect(() => {
    const getClarificationEffect = async (): Promise<void> => {
      setClarificationData({ ...clarificationData, loading: true });
      if (activeChatId) {
        const res = await getClarification(activeChatId);
        setClarificationData({ loading: false, data: res });
      } else {
        setClarificationData({ loading: false, data: undefined });
      }
    };
    if (activeChatId !== "new") {
      void getClarificationEffect();
    } else {
      setClarificationData({ loading: false, data: undefined });
    }
  }, [activeChatId]);

  return (
    <div className={styles.container} onMouseUp={handleTextHighlight}>
      {activeChatId && currentView === ViewState.ClarificationChat ? (
        <div style={{ height: "100%", display: "flex" }}>
          <ChatView
            chatId={activeChatId}
            clarification={clarificationData.data}
            isClarificationLoading={clarificationData.loading}
          />
        </div>
      ) : (
        lesson.pages.length > 0 &&
        lesson.pages[currentPageIndex] && (
          <>
            <div className={styles.lessonContent}>
              <div className={styles.lessonContainer}>
                <div className={styles.topbar}>
                  <h2 className={styles.topbarTitle}>
                    {currentView === ViewState.Github ? "Github" : "Lesson"}
                  </h2>
                  {currentView !== ViewState.Github && (
                    <button
                      onClick={() => updateCurrentPageIndex(0)}
                      className={styles.topbarButton}
                    >
                      <FontAwesomeIcon icon={faBook} /> Outline
                    </button>
                  )}
                </div>
                {currentView === ViewState.Github ? (
                  <GithubComponent />
                ) : (
                  <>
                    {currentPageIndex === 0 && (
                      <h2 className={styles.pageTitle}>
                        {lesson.pages[0]?.title}
                      </h2>
                    )}
                    <div className={styles.sectionWrapper}>
                      {lesson.pages[currentPageIndex].sections.map(
                        (section, sectionIndex) => (
                          <LessonSectionRender
                            section={section}
                            key={sectionIndex}
                            pageId={lesson.pages[currentPageIndex].id}
                            lessonId={lessonId}
                            isLastSection={
                              currentPageIndex === lesson.pages.length - 1 &&
                              lesson.pages[currentPageIndex].sections.length ===
                                sectionIndex + 1
                            }
                            isLessonLoading={
                              isLessonLoading || !isStreamingCompleted
                            }
                          />
                        )
                      )}
                    </div>
                  </>
                )}
              </div>
              <div onClick={(e) => e.stopPropagation()}>
                {isShowingPopover && (
                  <AIClarificationPopover
                    onSubmit={handleSubmitClarification}
                    isShowingPopover={isShowingPopover}
                    popoverPosition={popoverPosition}
                    setIsShowingPopover={setIsShowingPopover}
                  />
                )}
              </div>
            </div>
            <PageMenu
              currentPageId={lesson.pages[currentPageIndex].id}
              currentPageIndex={currentPageIndex}
              totalPages={lesson.pages.length}
              isLoading={isLoading}
              isPageCompleted={isPageCompleted}
              updateCurrentPageIndex={updateCurrentPageIndex}
              togglePageCompletion={toggleMarkPageCompleted}
            />
          </>
        )
      )}
    </div>
  );
};

import React from "react";
import { TreeNodeDataType } from "../../utils";
import { NodeModel } from "@minoru/react-dnd-treeview/dist/types";
import { updateFile } from "../../components/utils/updateFileContent";

export interface DirectoryContextValue {
  directoryTree?: Array<NodeModel<TreeNodeDataType>>;
  setDirectoryTree?: (
    directoryTree: Array<NodeModel<TreeNodeDataType>>,
  ) => void;
  writeToDirectory?: (
    value: string | undefined,
    relativeParentPath: string,
    fileName: string,
    terminalSessionId?: string,
  ) => void;
  openedFileId?: string | number | undefined;
  setOpenedFileId?: (openedFileId: string | number | undefined) => void;
}

export const DirectoryContext = React.createContext<DirectoryContextValue>({});

interface DirectoryProviderProps {
  children: React.ReactNode;
}

export function DirectoryProvider({
  children,
}: DirectoryProviderProps): React.ReactNode {
  const [directoryTree, setDirectoryTree] = React.useState<
    Array<NodeModel<TreeNodeDataType>>
  >([]);
  const [openedFileId, setOpenedFileId] = React.useState<string | number>();
  return (
    <DirectoryContext.Provider
      value={{
        directoryTree,
        setDirectoryTree,
        openedFileId,
        setOpenedFileId,
      }}
    >
      {children}
    </DirectoryContext.Provider>
  );
}

export function useDirectoryTree(): DirectoryContextValue {
  const { directoryTree, setDirectoryTree, openedFileId, setOpenedFileId } =
    React.useContext(DirectoryContext);

  const writeToDirectory = React.useCallback(
    (
      value: string | undefined,
      relativeParentPath: string,
      fileName: string,
      terminalSessionId?: string,
    ) => {
      if (terminalSessionId) {
        const fileUpdated = updateFile(
          relativeParentPath,
          fileName,
          terminalSessionId,
          value ?? "",
        );

        fileUpdated
          .then((maybeSuccessfulUpdate) => {
            if (!maybeSuccessfulUpdate) {
              console.error("Failed to sync changes to the server");
            }
          })
          .catch((e) =>
            console.error("Failed to sync changes to the server", e),
          );
      }
      setDirectoryTree?.(
        directoryTree?.map((data) => {
          if (data.id === openedFileId) {
            return { ...data, data: { content: value } };
          }
          return data;
        }) ?? [],
      );
    },
    [openedFileId, directoryTree],
  );

  return {
    directoryTree,
    setDirectoryTree,
    openedFileId,
    setOpenedFileId,
    writeToDirectory,
  };
}

import { apiDomain } from "../../../../../config";

export const createFile = async (
  sessionId: string,
  relativeParentPath: string,
  fileName: string,
): Promise<boolean> => {
  try {
    await fetch(`${apiDomain}/session-service/session/createFile`, {
      method: "POST",
      body: JSON.stringify({
        sessionId,
        relativeParentPath,
        file: {
          filename: fileName,
          contentType: 1,
          content: "",
        },
      }),
      credentials: "include",
      headers: {
        "Content-type": "application/json",
      },
    });
    return true;
  } catch (e) {
    return false;
  }
};

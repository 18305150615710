import React from "react";
import { Terminal } from "xterm";
import { createSession } from "../../components/utils/createSession";
import { getUserSessionForLesson } from "../../components/utils/getUserSessionForLesson";

export interface TerminalSessionContextValue {
  terminalSessionId?: string;
  setTerminalSessionId?: (terminalSessionId?: string) => void;
  programToRun?: string;
  setProgramToRun: (programToRun?: string) => void;
  terminalConnected: boolean;
  setTerminalConnected: React.Dispatch<React.SetStateAction<boolean>>;
  activeTerminal?: ITerminal;
  setActiveTerminal: (terminal: ITerminal) => void;
  activeProcesses: Set<string>;
  setActiveProcesses: (processes: Set<string>) => void;
  handleAddTerminal: (
    lessonId?: string,
    terminalName?: string,
  ) => Promise<void>;
  terminals: ITerminal[];
  clearTerminals: () => void;
  deleteTerminal: (id: string) => void;
  terminalDisplayed: boolean;
  showTerminal: () => void;
  hideTerminal: () => void;
}

export const TerminalSessionContext =
  React.createContext<TerminalSessionContextValue>({
    terminalConnected: false,
    setProgramToRun: (_programToRun) => null,
    setTerminalConnected: () => null,
    setActiveTerminal: () => null,
    handleAddTerminal: async (_lessonId, _terminalName) =>
      await new Promise((resolve) => resolve()),
    terminals: [],
    clearTerminals: () => null,
    deleteTerminal: (_id) => null,
    terminalDisplayed: false,
    hideTerminal: () => null,
    showTerminal: () => null,
    activeProcesses: new Set(),
    setActiveProcesses: () => null,
  });

interface TerminalSessionProviderProps {
  children: React.ReactNode;
}

export interface ITerminal {
  name: string;
  id: string;
  terminal: Terminal;
}

export const TERMINAL_BACKGROUND = "#161817";
export const TERMINAL_FOREGROUND = "#C5C9C7";

export function TerminalSessionProvider({
  children,
}: TerminalSessionProviderProps): React.ReactNode {
  const [terminalSessionId, setTerminalSessionId] = React.useState<string>();
  const [programToRun, setProgramToRun] = React.useState<string>();
  const [terminalConnected, setTerminalConnected] =
    React.useState<boolean>(false);

  const [terminals, setTerminals] = React.useState<ITerminal[]>([]);
  const [activeTerminal, setActiveTerminal] = React.useState<ITerminal>();
  const [terminalDisplayed, setShowTerminal] = React.useState<boolean>(false);
  const [activeProcesses, setActiveProcesses] = React.useState<Set<string>>(
    new Set(),
  );

  const handleAddTerminal = React.useCallback(
    async (lessonId?: string, terminalName?: string): Promise<void> => {
      if (!terminalSessionId && lessonId) {
        const maybeSessionId = await getUserSessionForLesson(lessonId);
        if (!maybeSessionId) {
          const sessionId = await createSession(lessonId);
          setTerminalSessionId?.(sessionId);
        } else {
          setTerminalSessionId?.(maybeSessionId);
        }
      }
      const terminal = new Terminal({
        theme: {
          background: TERMINAL_BACKGROUND,
          foreground: TERMINAL_FOREGROUND,
          cursor: TERMINAL_FOREGROUND,
          selectionBackground: TERMINAL_FOREGROUND,
          selectionForeground: TERMINAL_BACKGROUND,
        },
        cursorBlink: true,
        rows: Math.ceil((screen.height * 0.25) / 17),
        cols: 80,
      });
      let termName = terminalName ?? `Bash terminal ${terminals.length + 1}`;
      terminals.forEach((term) => {
        if (term.name === termName) {
          termName += "_1";
        }
      });
      const newTerminal: ITerminal = {
        name: termName,
        id: crypto.randomUUID(),
        terminal,
      };
      setTerminals([...terminals, newTerminal]);
      setActiveTerminal(newTerminal);
    },
    [terminals, setTerminals, setActiveTerminal],
  );

  const clearTerminals = React.useCallback(() => {
    setTerminals([]);
  }, [setTerminals]);

  const deleteTerminal = React.useCallback(
    (id: string) => {
      setTerminals(terminals.filter((term) => term.id !== id));
    },
    [setTerminals],
  );

  return (
    <TerminalSessionContext.Provider
      value={{
        terminalSessionId,
        setTerminalSessionId,
        programToRun,
        setProgramToRun,
        terminalConnected,
        setTerminalConnected,
        handleAddTerminal,
        activeTerminal,
        setActiveTerminal,
        terminals,
        clearTerminals,
        deleteTerminal,
        terminalDisplayed,
        showTerminal: () => setShowTerminal(true),
        hideTerminal: () => setShowTerminal(false),
        activeProcesses,
        setActiveProcesses
      }}
    >
      {children}
    </TerminalSessionContext.Provider>
  );
}

export function useTerminalSession(): TerminalSessionContextValue {
  const terminalContext = React.useContext(TerminalSessionContext);
  return {
    ...terminalContext,
  };
}

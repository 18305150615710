import React from "react";
import styles from "./input.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

interface InputProps {
  className: string;
  type?: string;
}

export default function Input(
  props: InputProps &
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
): JSX.Element {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const toggleShowPassword = React.useCallback(() => {
    if (inputRef.current) {
      inputRef.current.type =
        inputRef.current.type === "password" ? "text" : "password";
    }
  }, [inputRef.current]);

  return (
    <div className={props.className + " " + styles.inputContainer}>
      <input
        ref={inputRef}
        {...props}
        className={props.className + " " + styles.input}
        style={{
          paddingRight: props.type === "password" ? "2rem" : "auto",
          paddingLeft: props.type === "password" ? "2rem" : "auto",
        }}
      />
      {props.type === "password" && (
        <button
          type="button"
          onClick={toggleShowPassword}
          className={styles.toggleShowPasswordButton}
        >
          <FontAwesomeIcon icon={faEye} />
        </button>
      )}
    </div>
  );
}

import React from "react";
import {
  getDescendants,
  type NodeModel,
  type TreeMethods,
} from "@minoru/react-dnd-treeview";

interface ITreeOpenHandler {
  ref: React.MutableRefObject<TreeMethods | null>;
  open: (id: number | string) => void;
  close: (id: number | string) => void;
  toggle: (id: number | string) => void;
  getPipeHeight: (id: number | string, treeData: NodeModel[]) => number;
  isVisible: (id: number | string, treeData: NodeModel[]) => boolean;
  openIds: Array<string | number>;
}

const useTreeOpenHandler = (): ITreeOpenHandler => {
  const ref = React.useRef<TreeMethods | null>(null);

  const [openIds, setOpenIds] = React.useState<Array<string | number>>([]);

  const open = (id: string | number): void => {
    ref.current?.open(id);
    setOpenIds((p) => {
      return p.includes(id) ? p : [...p, id];
    });
  };
  const close = (id: string | number): void => {
    ref.current?.close(id);
    setOpenIds((p) => {
      return [...p.filter((v) => v !== id)];
    });
  };
  const toggle = (id: string | number): void => {
    openIds.includes(id) ? close(id) : open(id);
  };

  const isVisible = (id: string | number, treeData: NodeModel[]): boolean => {
    const parentId = treeData.find((node) => node.id === id)?.parent;
    const parentExistsInTree =
      parentId && treeData.find((node) => node.id === parentId);
    if (parentExistsInTree) {
      const isParentVisible = openIds.includes(parentId);
      return isParentVisible ? isVisible(parentId, treeData) : false;
    } else {
      return true;
    }
  };

  const getPipeHeight = (
    id: string | number,
    treeData: NodeModel[]
  ): number => {
    treeData = getDescendants(treeData, id);
    const ROW_HEIGHT = 32;
    const LIST_PADDING = 5;

    const droppableHeightExceedsRow = (node: NodeModel): boolean | undefined =>
      node?.droppable &&
      openIds.includes(node.id) &&
      treeData.filter((n) => n.parent === node.id).length > 0;

    const getHeightOfId = (id: string | number): number => {
      const directChildren = treeData.filter((node) => node.parent === id);
      const heightOfChildren = directChildren.map((node) =>
        droppableHeightExceedsRow(node)
          ? getHeightOfId(node.id) + ROW_HEIGHT + LIST_PADDING
          : ROW_HEIGHT
      );
      const height = heightOfChildren.reduce((a, b) => a + b, 0);
      return height;
    };

    const lastChild = treeData
      .filter((node) => node.parent === id)
      .reverse()[0];
    if (droppableHeightExceedsRow(lastChild)) {
      return getHeightOfId(id) - getHeightOfId(lastChild.id) - LIST_PADDING;
    }

    return getHeightOfId(id);
  };

  return { ref, open, close, toggle, getPipeHeight, isVisible, openIds };
};

export default useTreeOpenHandler;

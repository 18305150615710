import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useUserApiClient } from "../../../hooks/useUserApiClient";
import { FEEDBACK_URL } from "../FeedbackButton";
import { useLessonAuthorId, useLessonId } from "../LessonContext/LessonContext";
import Logo from "../Logo/logo";
import styles from "./DashboardHeaderComponent.module.css";
import { DashboardHeaderContext } from "./DashboardHeaderContext";
import LessonEditButton from "./LessonEditButton/LessonEditButton";
import LessonViewButton from "./LessonViewButton/LessonViewButton";
import { branding } from "../../../config/branding";
import DecentralHubLogo from "../../../assets/decentralhub-logo.png";

interface HeaderProps {
  showBrowser: boolean;
  headerText?: string;
  toggleShowBrowser: () => void;
}
export default function DashboardHeaderComponent({
  showBrowser,
  headerText,
  toggleShowBrowser,
}: HeaderProps): JSX.Element {
  const { signedInUser } = useUserApiClient();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const history = useHistory();
  const lessonAuthorId = useLessonAuthorId();
  const lessonId = useLessonId();
  const { isTransparent } = useContext(DashboardHeaderContext);

  const showEditLessonButton = useMemo(() => {
    return (
      signedInUser?.id === lessonAuthorId &&
      window.location.pathname.includes("/lesson/")
    );
  }, [lessonAuthorId, signedInUser?.id]);

  const showViewLessonButton = useMemo(() => {
    return (
      signedInUser?.id === lessonAuthorId &&
      window.location.pathname.includes("/lesson-creation")
    );
  }, [lessonAuthorId, signedInUser?.id]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.desktopContainer}>
          <div
            className={`${styles.titleBar} ${
              isTransparent ? styles.titleBarTransparent : ""
            }`}
            onClick={() => history.push("/menu")}
          >
            {branding.name === "DecentralHub" ? (
              <img src={DecentralHubLogo} style={{ width: "100%" }} />
            ) : (
              <Logo />
            )}
          </div>
          <div className={styles.rightNav}>
            <div className={styles.leftBar}>
              <span className={styles.welcome}>{headerText}</span>
            </div>
            <div className={styles.rightBar}>
              {/* {showEditLessonButton && (
                <LessonEditButton
                  lessonId={lessonId ?? ""}
                  shouldStyle={true}
                />
              )} */}
              {/* {showViewLessonButton && (
                <LessonViewButton
                  lessonId={lessonId ?? ""}
                  shouldStyle={true}
                />
              )} */}
              <div className={styles.userProfileSection}>
                <div className={styles.profileImage}>
                  <FontAwesomeIcon
                    icon={faBell}
                    className={styles.notifications}
                  />
                </div>
                <div className={styles.profileImage}>
                  {signedInUser?.profilePictureUrl ? (
                    <img
                      src={signedInUser.profilePictureUrl}
                      alt="Profile img"
                      height={"100%"}
                      width={"100%"}
                    />
                  ) : (
                    <div
                      className={styles.profileImage}
                      style={{ backgroundColor: "#29834D", color: "white" }}
                    >
                      {signedInUser?.firstName
                        ? signedInUser?.firstName[0]
                        : ""}
                    </div>
                  )}
                </div>
                <div>
                  <div className={styles.username}>
                    {signedInUser?.firstName} {signedInUser?.lastName}
                  </div>
                  <div className={styles.userEmail}>{signedInUser?.email}</div>
                </div>
                <div className={styles.arrow}>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.mobileContainer}>
          <div
            className={`${styles.titleBar}`}
            onClick={() => history.push("/menu")}
          >
            <Logo />
          </div>

          <div className={styles.rightBar}>
            <FontAwesomeIcon icon={faBell} className={styles.notifications} />
            <button
              className={styles.mobileMenuButton}
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <FontAwesomeIcon icon={faBars} className={styles.menuBars} />
            </button>
            {showMobileMenu && (
              <div
                className={styles.mobileMenuOverlay}
                onClick={() => setShowMobileMenu(false)}
              >
                <div className={styles.mobileMenu}>
                  <Link to="/menu">Home</Link>
                  <Link to={`/profile/${signedInUser ? signedInUser.id : ""}`}>
                    Profile
                  </Link>
                  {showEditLessonButton && (
                    <LessonEditButton
                      lessonId={lessonId ?? ""}
                      shouldStyle={false}
                    />
                  )}
                  {showViewLessonButton && (
                    <LessonViewButton
                      lessonId={lessonId ?? ""}
                      shouldStyle={false}
                    />
                  )}
                  <Link to={FEEDBACK_URL}>Give Feedback</Link>
                  <Link to="/logout" className={styles.logoutButton}>
                    Logout
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

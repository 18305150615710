import Editor, { OnMount } from "@monaco-editor/react";
import React, { useMemo } from "react";
import { getAbsoluteNodePath, getMonacoModelPath } from "../../utils";
import { useMonaco } from "../../contexts/MonacoContext/MonacoContext";
import { useDirectoryTree } from "../../contexts/DirectoryContext/DirectoryContext";
import { useTerminalSession } from "../../contexts/TerminalSessionContext/TerminalSessionContext";
import styles from "./ChamEditor.module.css";
import { NoFileOpenedEditor } from "./NoFileOpenedEditor";
import { RunButton } from "./components/RunButton/RunButton";
import { useUserApiClient } from "../../../../../hooks/useUserApiClient";
import { useAIPopover } from "../../../../Lesson/components/LessonContainer/useAIPopover";
import { usePopoverContext } from "../../contexts/PopoverContext/PopoverContext";
import { WebButton } from "./components/WebButton/WebButton";

interface IEditorProps {
  toggleShowChamBrowser: () => void;
}

export const ChamEditor: React.FC<IEditorProps> = ({
  toggleShowChamBrowser,
}): React.JSX.Element => {
  const { monaco, setMonaco } = useMonaco();
  const { signedInUser } = useUserApiClient();
  const { directoryTree, writeToDirectory, openedFileId } = useDirectoryTree();
  const { terminalSessionId } = useTerminalSession();
  const currentNode = useMemo(() => {
    const openedFile = directoryTree?.find((data) => data.id === openedFileId);
    return openedFile;
  }, [openedFileId, directoryTree]);
  const editorPosition = document
    .getElementById("editor")
    ?.getBoundingClientRect();

  const popoverInstance = useAIPopover();
  const { setHighlightedText } = usePopoverContext();
  const editorPath = useMemo(
    () =>
      currentNode && directoryTree
        ? getMonacoModelPath(currentNode, directoryTree)
        : undefined,
    [currentNode, directoryTree],
  );

  const { monaco: monacoEditor, refreshMonaco, setRefreshMonaco } = useMonaco();

  React.useEffect(() => {
    directoryTree?.forEach((directory) => {
      if (!directory.droppable) {
        const path = getMonacoModelPath(directory, directoryTree);
        const existingModel = monacoEditor?.editor.getModel(
          monacoEditor.Uri.parse(path),
        );
        if (!existingModel) {
          monacoEditor?.editor.createModel(
            directory.data?.content ?? "",
            undefined,
            monacoEditor.Uri.parse(path),
          );
        } else {
          existingModel.setValue(directory.data?.content ?? "");
        }
      }
    });
    setRefreshMonaco?.(false);
  }, [refreshMonaco, setRefreshMonaco, directoryTree]);

  const handleEditorDidMount: OnMount = React.useCallback(
    (editor, monaco) => {
      monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true);
      monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: true,
        noSyntaxValidation: false,
      });

      monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        target: monaco.languages.typescript.ScriptTarget.ES2016,
        allowNonTsExtensions: true,
        allowJs: true,
        moduleResolution:
          monaco.languages.typescript.ModuleResolutionKind.NodeJs,
        module: monaco.languages.typescript.ModuleKind.ESNext,
      });

      monaco.editor.defineTheme("chameleon-dark", {
        base: "vs-dark",
        inherit: true,
        rules: [],
        colors: {
          "editor.background": "#161817",
        },
      });

      monaco.editor.setTheme("chameleon-dark");

      if (signedInUser != null) {
        editor.addAction({
          // An unique identifier of the contributed action.
          id: "clarify-with-cham",

          // A label of the action that will be presented to the user.
          label: "ChamAI Explain",

          // A precondition for this action.
          precondition: undefined,

          // A rule to evaluate on top of the precondition in order to dispatch the keybindings.
          keybindingContext: undefined,

          contextMenuGroupId: "navigation",

          contextMenuOrder: 1.5,

          // Method that will be executed when the action is triggered.
          // @param editor The editor instance is passed in as a convenience
          run: async function (ed) {
            const highlightedText = window.getSelection()?.toString();
            if (highlightedText) {
              setHighlightedText?.(highlightedText);
              await popoverInstance.handleTerminalClarifyWithCham(
                highlightedText,
                "Clarify",
              );
            }
          },
        });
      }

      directoryTree?.forEach((directory) => {
        if (!directory.droppable) {
          const path = getMonacoModelPath(directory, directoryTree);
          const existingModel = monaco.editor.getModel(monaco.Uri.parse(path));
          if (!existingModel) {
            monaco.editor.createModel(
              directory.data?.content ?? "",
              undefined,
              monaco.Uri.parse(path),
            );
          } else {
            existingModel.setValue(directory.data?.content ?? "");
          }
        }
      });
    },
    [monaco, setMonaco, directoryTree, editorPosition],
  );

  const relativeParentPath = useMemo(() => {
    // TODO: Don't believe this needs to be done
    if (currentNode) {
      return getAbsoluteNodePath(currentNode, directoryTree ?? []);
    }
  }, [currentNode, directoryTree]);

  const editorPathDisplayed = React.useMemo(() => {
    return editorPath?.replace("root/", "/workspace/");
  }, [editorPath]);

  return openedFileId != null ? (
    <>
      <div className={styles.editorToolbar}>
        <h2>{editorPathDisplayed}</h2>
        <div className={styles.editorToolbarButtons}>
          <RunButton editorPath={editorPath} />
          <WebButton
            className={styles.editorToolbarWebButton}
            toggleShowChamBrowser={toggleShowChamBrowser}
          />
        </div>
      </div>
      <div className={styles.editor}>
        <Editor
          // theme="chameleon-dark"
          options={{
            fontSize: 15,
            scrollBeyondLastLine: false,
          }}
          value={currentNode?.data?.content ?? ""}
          path={editorPath}
          onMount={handleEditorDidMount}
          onChange={(text) =>
            relativeParentPath &&
            currentNode &&
            writeToDirectory?.(
              text,
              relativeParentPath,
              currentNode?.text,
              terminalSessionId,
            )
          }
        />
      </div>
    </>
  ) : (
    <NoFileOpenedEditor />
  );
};

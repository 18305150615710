export interface Branding {
  name: "BuildQL" | "DecentralHub";
  publicLogo: string;
}

let branding: Branding;
if (window.location.host.includes("decentralhub.buildql.com")) {
  branding = {
    name: "DecentralHub",
    publicLogo: "/decentralhub-logo.png",
  };
} else {
  branding = {
    name: "BuildQL",
    publicLogo: "/logo.png",
  };
}

export { branding };

import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import styles from "./TerminalMenu.module.css";
import { useTerminalSession } from "../../contexts/TerminalSessionContext/TerminalSessionContext";
interface TerminalMenuProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
}

export const TerminalMenu: React.FC<TerminalMenuProps> = ({
  anchorEl,
  setAnchorEl,
}) => {
  const [showDel, setShowDel] = React.useState<string>();
  const open = Boolean(anchorEl);
  const handleClose = React.useCallback((): void => {
    setAnchorEl(null);
  }, []);
  const {
    terminals,
    activeTerminal,
    setActiveTerminal,
    deleteTerminal,
    showTerminal,
  } = useTerminalSession();

  const handleDeleteTerminal = React.useCallback(
    (id: string, e: React.MouseEvent) => {
      e.stopPropagation();
      if (activeTerminal?.id === id) {
        setActiveTerminal(terminals[0]);
      }
      deleteTerminal(id);
    },
    [terminals],
  );
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {terminals.map((terminal) => (
        <MenuItem
          key={terminal.id}
          onClick={() => {
            setActiveTerminal(terminal);
            showTerminal();
            handleClose();
          }}
          onMouseEnter={() => setShowDel(terminal.id)}
          onMouseLeave={() => setShowDel(terminal.id)}
          selected={activeTerminal?.id === terminal.id}
          style={{
            fontFamily: "CircularStd",
            fontWeight: 600,
            fontSize: "0.8em",
            background: "#BEC1BF",
          }}
        >
          {terminal.name}
          {showDel === terminal.id && (
            <FontAwesomeIcon
              icon={faTrash}
              onClick={(e) => handleDeleteTerminal(terminal.id, e)}
              className={styles.trash}
            />
          )}
        </MenuItem>
      ))}
    </Menu>
  );
};

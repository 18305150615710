import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LessonProvider } from "../common_components/LessonContext/LessonContext";
import Layout from "../common_components/LessonLayout/Layout";
import { LessonContainer } from "./components/LessonContainer/LessonContainer";
import useLessonApiClient from "../../hooks/useLessonApiClient";
import DashboardHeaderProvider from "../common_components/DashboardHeaderComponent/DashboardHeaderContext";
import { ClarificationChatProvider } from "../common_components/ChamIDE/contexts/ClarificationChatContext/ClarificationChatContext";
import { PopoverProvider } from "../common_components/ChamIDE/contexts/PopoverContext/PopoverContext";
import { ViewContextProvider } from "../common_components/ChamIDE/contexts/ViewContext/ViewContext";
import CheckLoginComponent from "../common_components/CheckLoginComponent";
import CheckSubscriptionComponent from "../common_components/CheckSubscriptionComponent";

export default function LessonView(): React.JSX.Element {
  const { lessonId } = useParams<{ lessonId: string }>();
  const [lessonAuthorId, setLessonAuthorId] = useState<string>("");
  const { fetchLesson } = useLessonApiClient();
  const [codeChatId, setCodeChatId] = React.useState<string>();
  const [activeChatId, setActiveChatId] = React.useState<string>();
  const [fetchClarificationsTrigger, setFetchClarificationsTrigger] =
    React.useState<number>(0);

  useEffect(() => {
    fetchLesson(lessonId)
      .then((result) => {
        setLessonAuthorId(result.authorId);
      })
      .catch((_error) => {
        setLessonAuthorId("");
      });
  }, [lessonId]);

  return (
    <CheckLoginComponent>
      <CheckSubscriptionComponent>
        <LessonProvider lessonId={lessonId} lessonAuthorId={lessonAuthorId}>
          <DashboardHeaderProvider isTransparent={true}>
            <ClarificationChatProvider
              setCodeChatId={setCodeChatId}
              activeChatId={activeChatId}
              setActiveChatId={setActiveChatId}
              codeChatId={codeChatId}
              fetchClarificationsTrigger={fetchClarificationsTrigger}
              setFetchClarificationsTrigger={setFetchClarificationsTrigger}
            >
              <PopoverProvider>
                <ViewContextProvider>
                  <Layout>
                    <LessonContainer lessonId={lessonId} />
                  </Layout>
                </ViewContextProvider>
              </PopoverProvider>
            </ClarificationChatProvider>
          </DashboardHeaderProvider>
        </LessonProvider>
      </CheckSubscriptionComponent>
    </CheckLoginComponent>
  );
}

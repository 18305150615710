import React from "react";
import styles from "./BuildExampleCard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface BuildCardProps {
  icon: IconProp;
  appName: string;
  runFunction: () => Promise<void>;
  subText: string;
  className?: string;
}

export const BuildExampleCard: React.FC<BuildCardProps> = ({
  icon,
  appName,
  runFunction,
  subText,
  className = "",
}) => {
  return (
    <div
      className={`${styles.cardContainer} ${className}`}
      onClick={() => {
        void runFunction();
      }}
    >
      <div className={styles.cardIcon}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className={styles.cardText}>
        <div className={styles.cardTitle}>{appName}</div>
        <div className={styles.cardBody}>{subText}</div>
      </div>
    </div>
  );
};

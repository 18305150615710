import { useCallback, useEffect, useState } from "react";
import StompJS from "stompjs";

export interface SessionResponse {
  message: string;
  seqNo: number;
}

export const useCodeExecutionService = (
  stompClient: StompJS.Client | undefined,
  isStompConnected: boolean,
  sessionId: string | undefined,
  setResponseReceived: (output?: SessionResponse) => void,
  terminalId: string | undefined,
  activeProcesses: Set<string>,
  connectionToken: string,
): boolean => {
  const [processStarted, setProcessStarted] = useState<boolean>(false);

  const setupStompClientSubscriptions = useCallback(
    (stompClient: StompJS.Client, threadId: string) => {
      const handleAcknowledgements = (): void => {
        setResponseReceived(undefined);
        setProcessStarted(true);
      };

      const handleResponse = (response: StompJS.Message): void => {
        setResponseReceived(JSON.parse(response.body));
      };

      const handleErrors = (error: StompJS.Message): void => {
        console.log(error);
      };

      const handleTermination = (): void => {
        subscriptions.forEach((subscription) => {
          subscription.unsubscribe();
        });
        console.log("Terminated");
        setProcessStarted(false);
      };

      const responseSubscription = stompClient.subscribe(
        "/responses/thread-" + threadId,
        handleResponse,
      );
      const errorSubscription = stompClient.subscribe(
        "/errors/thread-" + threadId,
        handleErrors,
      );
      const terminationSubscription = stompClient.subscribe(
        "/terminate/thread-" + threadId,
        handleTermination,
      );
      const acknowledgementsSubscription = stompClient.subscribe(
        "/acknowledgements/thread-" + threadId,
        handleAcknowledgements,
      );

      const subscriptions = [
        responseSubscription,
        errorSubscription,
        terminationSubscription,
        acknowledgementsSubscription,
      ];
    },
    [setResponseReceived, processStarted],
  );

  const startSession = useCallback(
    (stompClient: StompJS.Client, threadId: string): void => {
      stompClient.send(
        "/app/startSession",
        {},
        JSON.stringify({
          threadId,
          sessionId,
          threadType: "python",
          activeFile: "a.py",
          connectionToken,
        }),
      );
    },
    [sessionId, connectionToken],
  );

  useEffect(() => {
    if (
      stompClient &&
      sessionId != null &&
      isStompConnected &&
      terminalId &&
      !activeProcesses.has(terminalId)
    ) {
      setupStompClientSubscriptions(stompClient, terminalId);
      startSession(stompClient, terminalId);
    }
  }, [isStompConnected, sessionId, terminalId, stompClient, activeProcesses]);

  return processStarted;
};
